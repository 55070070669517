import { toGermanFormat } from "../../utils/numberFormater";
import moment from 'moment/moment';

export const formatPercentage = (number, multiplier = 1) => {
  return (number || number === 0)
    ? toGermanFormat(number * multiplier, '', ' %', 2)
    : 'k.A.';
};

export const formatYesNo = (bool) => {
  return (bool === true) ? 'Ja' : (bool === false) ? 'Nein' : 'k.A.';
};

export const toShortGermanFormat = (number, prefix='', suffix='', fraction=2, lose_decimals=false) => {
  let _suffix = '';
  let value = number;

  try {
    if (number >= 1000 || number <= -1000) {
      _suffix = ' Tsd.';
      value = number / 1000;
    }
    if (number >= 1000000 || number <= -1000000) {
      _suffix = ' Mio.';
      value = number / 1000000;
    }

    return toGermanFormat(value, prefix, _suffix + suffix, fraction, lose_decimals);

  } catch (err) {
    return number;
  }
}

/**
 * Function to sort passed array by customSortFunction. If customSortFunction not passed - sorts by weight
 * @param array to sort
 * @param calculateSonstige flag that indicates if other should be added if it is not in array
 * @param skipOrdering flog to skip ordering by weight
 * @returns {*|*[]}
 */
export const sortByWeight = (array, calculateSonstige=true, skipOrdering) => {
  let otherNames = ['Sonstige', 'Other']
  // check if other is passed in array
  let other = array.find( obj => (otherNames.includes(obj.name)));
  // get array without other
  let sortedArr = array.filter( obj => (!otherNames.includes(obj.name)));
  // sorting array without order by weight
  if(!skipOrdering){
    sortedArr = sortedArr.sort((a,b) => b.weight - a.weight)
  }
  if (calculateSonstige) { // calculate other as amount left up to 100%
      let otherAmount = array.reduce(function(minuend, item) {
          return minuend - item.weight;
      }, 1);
      if(other) otherAmount += other.weight;
      if(otherAmount * 100 > 0.009) { // checking if "other" have sense to display, and we not show 0.00
          sortedArr.push({name: 'Other', weight: otherAmount})
      }
  } else if (other) {
      sortedArr.push({name: 'Other', weight: other.weight})
  }
  return array.length ? sortedArr : [];
}

export const isDateDifferenceEqualOrBiggerThanYear = (date) => {
  return moment().diff(moment(date), 'years') >= 1
}
