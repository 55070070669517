import React from 'react';
import {Grid} from "@material-ui/core";
import CustomerThemeProvider from "../../../components/CustomerThemeProvider/CustomerThemeProvider";
import {PortfolioStructure} from "../components/Widgets/components";
import _ from "lodash";
import {ChartSectionWrapper} from "../components_v2/ChartSectionBordered/ChartSectionBordered";

function StructureTab(props) {

  const handleOnExpandedChange = (value) => {
    props.onExpandedItemsChange('structureTab.structureSection', value)
  };

  const handleStructureChartExpandedChange = (sectionKey, expanded) => {
    props.onExpandedItemsChange(['structureTab', 'chartItems', sectionKey], expanded)
  };

  const preferredColor = props.selectedPortfolios && props.selectedPortfolios.length == 1
    ? _.get(props, 'selectedPortfolios.0.color')
    : undefined;

  return (
    <Grid item xs={12} id="portfolio-structure">
      <CustomerThemeProvider>
        <ChartSectionWrapper
          title={<b>Struktur</b>}
          content={
            <PortfolioStructure
              dashboardData={props.breakdownData}
              customTypeSettings={props.customSettings}
              reportType={props.reportType}
              preferredColor={preferredColor}
              expandedItems={props.expandedItems.structureTab.chartItems}
              onExpanded={handleStructureChartExpandedChange}
            />
          }
          loading={props.breakdownData.loading}
          error={props.breakdownData.errors}
          displayError={true}
          expanded={props.expandedItems.structureTab.structureSection}
          onExpanded={handleOnExpandedChange}
        />
      </CustomerThemeProvider>
    </Grid>
  )
}

StructureTab.propTypes = {}

StructureTab.propDefs = {}

StructureTab.defaultProps = {}

export default StructureTab;