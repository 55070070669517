import React from 'react';
import {connect} from 'react-redux';
import {createSelector} from "reselect";
import _ from "lodash";

/** Material-UI Components */
import {CircularProgress, Container, Divider, Grid, Paper, Tab, withStyles, withWidth} from '@material-ui/core';

/** BCA Modules */
import styles from './styles';
import {
  CUSTOMER_DOES_NOT_EXIST_ERROR_MODAL_MESSAGE,
  PERMISSION_ERROR_MODAL_MESSAGE,
  SELECTED_LONGER_TIMEFRAME
} from '../../utils/constants';
import {getFromStorage, SHARED_SETTINGS_KEY} from '../../utils/storage';
import setSticky from '../../utils/sticky';
import {getGuideSteps} from "./guide";

/** BCA Components */
import Navigation from './components/PortfolioNavigation';
import InvestmentDetailsNavigation, {
  DashboardPortfoliosSelectorSharedState,
  StickyNavigation
} from './components/InvestmentDetailsNavigation';
import ErrorModal from '../../components/ErrorModal';
import {GuideTour} from '../../components/GuideTour';
import withCustomersSelectorNew from "../../components/CustomersSelectorProviderNew";

import withDashboardData from '../../components/DashboardDataProvider/DashboardDataProviderV2';
import withTradingsData from '../../components/TradingsDataProvider';

import {CustomerReportSettingResource, QuestionnairesHandlerResource, SharedSettingsResource} from '../../utils/api';
import {hasPortfolios} from "../../components/CustomersSelectorProviderNew/utils";
import {getBanksCompanyIdToCodeMapping} from "../Trades/utils";
import {displayWarningSnackBar} from '../../components/SnackbarProvider/actions'
import {
  extendPortfoliosWithDataForTrading, getOrdersCount,
  OnboardingButtonAttributesHelper,
  preparePortfoliosForCombinedTrading,
  tradingPossible,
} from "./utils";
import {OBJECT_TYPES} from "../RiskProfiling/constants";
import {
  getErrorMessage,
  getReportGenerationSettings,
  isSectionVisible,
  onboardingOnCustomersChange, renderPortfolioDropdownName
} from '../../utils/utils';
import withNotification from "../../components/NotificationProvider";
import {getAuthSelector} from "../../utils/redaxSelectors";
import DownloadPdfDialog from "../../components/DownloadPdfModal/DownloadPdfModal";
import {setEditorVariables} from "../GroupReporting/actions";
import {REPORT_TYPE_TABS, TAB} from "./constants";
import OverviewTab from "./tabs/Overview";
import PerformanceTab from "./tabs/Performance";
import InstrumentsTab from "./tabs/Instruments";
import PaymentPlansTab from "./tabs/PaymentPlans";
import TransactionsTab from "./tabs/Transactions";
import StructureTab from "./tabs/Structure";
import KeyFiguresTab from "./tabs/KeyFigures";
import RiskTab from "./tabs/Risk";
import SustainabilityTab from "./tabs/Sustainability";
import {TabsSharedData} from "./components_v2/TabsSharedData/TabsSharedData";
import {
  AddProductsToComparison,
} from "../../components/SelectForProductsComparisonModal/SelectForProductsComparisonModal";
import {
  SelectCustomerDepotsForProductsComparisonModal
} from "./components_v2/AddToComparisonModal/AddToComparisonModal";
import { REPORT_TYPES } from '../DashboardSettings/components/CustomersList/components/ReportType/constants';
import TabsContainer from '../../components/TabsContainer';

const getGuide = (state) => state.get('guide')

export const getGuideSelector = createSelector(
  [getGuide],
  (guide) => guide.toJS()
)


const mapStateToProps = (state) => ({
  guide: getGuideSelector(state),
  auth: getAuthSelector(state),
  combinedTrading: state.get('combinedTrading').toJS()
});

export const getUniqErrorMsgFromErrors = (errors) => {
  if(_.isArray(_.get(errors, 'data'))){
    // get uniq error messages and concat them to single string
    errors = Object.values(errors.data); // get list of each file errors objects
    errors =errors.map(e => _.get(e, 'file', _.isString(e) ? e : '')); // getting only error messages
    errors = _.flatten(errors); // making one plain list with all errors
    errors = [...new Set(errors)].join('\n') // joining uniq messages in one error message
  }

  return getErrorMessage(errors)
}

const TAB_COMPONENT = {
  [TAB.OVERVIEW.id]: OverviewTab,
  [TAB.PERFORMANCE.id]: PerformanceTab,
  [TAB.INSTRUMENTS.id]: InstrumentsTab,
  [TAB.PAYMENT_PLANS.id]: PaymentPlansTab,
  [TAB.TRANSACTIONS.id]: TransactionsTab,
  [TAB.STRUCTURE.id]: StructureTab,
  [TAB.KEY_FIGURES.id]: KeyFiguresTab,
  [TAB.RISK.id]: RiskTab,
  [TAB.SUSTAINABILITY.id]: SustainabilityTab,
};

class CustomerDashboardV2 extends React.Component {
  /** Component initialisation */
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: undefined,
      pdfOpen: false,
      expandedItems: {
        historicalChart: true,
        performanceLineChart: true,
        performanceBarChart: true,
        riskChart: true,
        structureTab: {
          structureSection: true,
          chartItems: {}
        },
        paymentPlansItems: {},
        paymentPlans: true,
        payoutPlansItems: {},
        payoutPlans: true,
        switchPlansItems: {},
        switchPlans: true,
        instruments: true,
        // by default all shown
        instrumentsTab: {
          depots: {}, // list of depots
          securities: {}, // depots with Wertpapiere expanded
          baseFonds: {}, // MP/PI depots with Basisfonds expanded
          baseFondsSubItems: {},
          clearingAccounts: {}, // depots with Verrechnungskonto expanded
          transactionsSaldo: {}, // depots with Transaktionsaldo expanded
          instrumentsSubItems: {},
        },
        cumulativeReturnChart: true,
        transactions: true,

        //to include charts from pro view
        forecastChart: true,
        performanceTable: true,
        performanceTableItems: {},
        singlePerformance: true,
        singlePerformanceItems: {},

        // charts KeyFiguresTab
        keyIndicatorsChart: true,
        rollingVolatilityChart: true,
        rollingSharpeRatioChart: true,
        // charts from RiskAnalysisTab
        riskAnalyse: true,
        riskReturnChart: true,
        riskReturnChartItems: {},
        stressTestChart: true,
        stressTestMaximumLossChart: true,
        stressTestRecoveryPeriodChart: true,
        correlationMatrix: true,
        correlationMatrixItems: {},
        // ESG (Sustainability)Tab
        sustainabilityMetrics: true,
        esgScoreCorporate: true,
        esgScoreSovereign: true,
      },
      paymentPlanModalVisible: false,
      pdfExportBtnDisabled: false,
      activeTab: TAB.OVERVIEW.id,
      addToComparisonModalOpen: false
    }
  }

  componentDidMount() {
    setSticky('app-main', 'sticky-nav-stoper', 'investment-navigation-component-sticky', 'sticky-trigger');
    this.fetchVariables()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.auth.error) {
      if (this.props.investmentData.errors !== prevProps.investmentData.errors) {

        if (this.props.investmentData.errors) {

          this.setState({
            errorMessage: this.props.investmentData.errors
          })
        }
      }

      if (this.props.customer.errors && this.props.customer.errors !== prevProps.customer.errors) {
        if (this.props.customer.errors.status && this.props.customer.errors.status === 401) {
          return;
        }
        if (this.props.customer.errors.status && this.props.customer.errors.status === 404) {
          this.setState({
            errorMessage: CUSTOMER_DOES_NOT_EXIST_ERROR_MODAL_MESSAGE
          })
        } else if (this.props.customer.errors.status && this.props.customer.errors.status === 403) {
          this.setState({
            errorMessage: PERMISSION_ERROR_MODAL_MESSAGE
          });
        } else {
          this.setState({
            errorMessage: this.props.customer.errors
          });
        }
      }

      if (this.props.selectedRange.id && this.props.selectedRange.id !== prevProps.selectedRange.id) {
        if (getFromStorage(SHARED_SETTINGS_KEY)) {
          let appSettings = getFromStorage(SHARED_SETTINGS_KEY).app_settings || {};
          if (this.props.selectedRange.isBig && appSettings.calculation_warning_enabled) {
            this.props.dispatch(displayWarningSnackBar(SELECTED_LONGER_TIMEFRAME))
          }
        }
      }

    }

    if (prevProps.reportType != this.props.reportType || prevProps.customer != this.props.customer){
      const availableTabs = this.getAvailableTabs();
      if(!_.isEmpty(availableTabs) && !availableTabs.includes(this.state.activeTab)) {
        this.setState({activeTab: availableTabs[0]});
      }

      if (!_.isEmpty(this.state.expandedItems.instrumentsSubItems) || !_.isEmpty(this.state.expandedItems.baseFondsSubItems)) {
        this.setState({
          expandedItems: {
            ...this.state.expandedItems,
            instrumentsSubItems: {},
            baseFondsSubItems: {},
          }
        });
      }
    }
  }

  getAvailableTabs = () => {
    const dashboardSettings = _.get(this.props.customer, 'data.dashboard_settings', {});
    const customSettings = _.get(dashboardSettings, 'individual_settings', {});
    const reportType = this.props.reportType;

    let availableTabs = reportType === REPORT_TYPES.CUSTOM.value
      ? REPORT_TYPE_TABS[REPORT_TYPES.PRO.value].filter(tab_id => isSectionVisible(customSettings, tab_id, reportType))
      : REPORT_TYPE_TABS[reportType];
    if(!dashboardSettings.with_transactions_monitor){
      availableTabs = availableTabs.filter(tab_id => tab_id !== TAB.TRANSACTIONS.id);
    }
    if(!dashboardSettings.with_payment_plans){
      availableTabs = availableTabs.filter(tab_id => tab_id !== TAB.PAYMENT_PLANS.id);
    }

    return availableTabs;
  };


  /** Call end point to download pdf file */
  handlePdfExportClick = () => {

    this.setState({
      pdfOpen: true
    })
  };

  handleAddToComparisonClick = () => {
    this.setState({
      addToComparisonModalOpen: true
    })
  }

  handleAddToComparisonClose = () => {
    this.setState({
      addToComparisonModalOpen: false
    })
  }

  fetchPdfFile = async (skip_expanded, skip_sub_depot_expanded, cover_text_enabled, cover_message_content, extra_files) => {
    this.setState({
      pdfExportBtnDisabled: true // disable pdf export btn when fetch starts
    });
    const {
      computedMatch: {
        params: {
          customer_id
        }
      },
    } = this.props;

    try{
      let formData = getReportGenerationSettings(customer_id, this.props.selectedPortfolios, this.props.portfolios,
        this.props.reportType, this.props.selectedDates, skip_expanded, skip_sub_depot_expanded, this.state.expandedItems, cover_text_enabled, cover_message_content,
         this.props.includeHistoricalPortfolios, extra_files, this.props.chartsSettings, this.props.instrumentsGroupBy, this.props.excludedPortfolios);
      formData.append('new_design', true);
      await CustomerReportSettingResource.at(`report/ad-hoc/`).post(formData);

      this.setState({
        pdfOpen: false // close pdf export modal on success
      });
    } catch (errors) {
      this.setState({
        errorMessage: getUniqErrorMsgFromErrors(errors)
      })
    }

    this.setState({
      pdfExportBtnDisabled: false // enable pdf export btn when fetch starts
    });
  };

  handleErrorModalClose = () => {
    this.setState({
      errorMessage: undefined
    })
  };

/**
 * Update expanded items state.
 *
 * @param {Array|string} path The path of the property to set.
 * @param {boolean} expanded Expanded flag
 */
  handleExpandedItemsChange = (path, expanded) => {
    this.setState((prevState) => {
      // TODO: cloneDeep will make all objects changed
      const expandedItems = _.cloneDeep(prevState.expandedItems);
      _.set(expandedItems, path, expanded);

      return {
        expandedItems: expandedItems
      }
    })
  };

  getCustomerData = (customer) => {
    if(!_.isEmpty(customer)){
      return {
        report_settings: {
          ...customer.user.settings,
        },
        customer_id: customer.customer_id
      }
    }
  };

  fetchVariables = async () => {
    try {
      const response = await SharedSettingsResource.at('editor-variables/').get();
      this.props.dispatch(setEditorVariables(response));
      window.editorVariables = response;
    } catch (e) {
      this.props.dispatch(setEditorVariables(undefined));
      window.editorVariables = undefined;
    }
  };

  /** Component rendering */
  render() {
    const {
      classes,
      customer,
      portfolios,
      investmentData,
      dashboardData,
      instrumentList,
      historicalData,
      profitAndLoss,
      paymentPlans,
      payoutPlansData,
      switchPlansData,
      breakdownData,
      timeWeightedReturnData,
      dataLoading,
      customerDashboard,
      includeHistoricalPortfolios,
      reportType,
      setReportType,
      selectedPortfolios,
      refresh,
      auth: {
        user: {
          broker_id
        }
      }
    } = this.props;

    const selectedPortfoliosName = renderPortfolioDropdownName(
      this.props.selectedPortfolios || [], this.props.portfolios || []);

    const isCustomerDataMounted = !customer.loading && !_.isEmpty(customer.data);
    const portfoliosToValidate = _.flatten([instrumentList.data || [], profitAndLoss.data || []]);
    const customerHasTradings = tradingPossible(portfoliosToValidate,this.props.banksMappingData || {});
    const dashboardSettings = _.get(customer, 'data.dashboard_settings', {});
    const customSettings = _.get(dashboardSettings, 'individual_settings', {});
    let availableTabs = this.getAvailableTabs();

    const TabComponent = TAB_COMPONENT[this.state.activeTab];

    const ordersCount = getOrdersCount(this.props.combinedTradings, this.props.combinedTradingSession.data);

    const actionBarProps = {
      handlePdfExportClick: this.handlePdfExportClick,
      pdfExportDisabled: _.isEmpty(selectedPortfolios),
      onAddToComparisonClick: this.handleAddToComparisonClick,
      ordersCount: ordersCount,
      customerHasTradings: customerHasTradings,
      onGoToTradeDetails: this.props.goToTradeDetails,
      tradingSession: this.props.combinedTradingSession.data,
    };

    return (
      <React.Fragment>
        {/*<GuideTour*/}
        {/*  steps={getGuideSteps(UserUtils.getCustomerReportType(customer.data), withOtherAssets)}*/}
        {/*  title="Vermögensübersicht Info-Tour"*/}
        {/*/>*/}
        <Container className={`app-page-container ${classes.container}`}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Navigation
                customerId={customer.data && customer.data.customer_id}
                brokerId={broker_id}
                customerFullName={customer.data && customer.data.customer_full_name}
                customerHasDashboardGroups={customer.data && customer.data.has_dashboard_groups}
                dashboardSettings={dashboardSettings}
                refresh={refresh}
                onChartSettingsChange={this.props.handleChartSettingsChange}
                dashboardSettingsLoaded={isCustomerDataMounted}
                {...actionBarProps}
              />
            </Grid>
            <DashboardPortfoliosSelectorSharedState>
              <StickyNavigation
                includeHistoricalPortfolios={includeHistoricalPortfolios}
                calculationDates={this.props.selectedDates}
                handleCalculationDatesChanged={this.props.onSelectedDatesChanged}
                portfolios={portfolios.data}
                portfoliosLoadingError={portfolios.errors}
                portfoliosDataLoading={portfolios.loading}
                handleSelectedPortfolioChanged={this.props.onSelectedPortfoliosChanged}
                selectedPortfolios={this.props.selectedPortfolios}
                calculationDatesType={this.props.selectedDatesType}
                investmentData={investmentData.data}
                dataLoading={dataLoading}
                dashboardSettingsLoaded={isCustomerDataMounted}
                instrumentListDataLoading={instrumentList.loading}
                dispatch={this.props.dispatch}
                customerDashboard={customerDashboard.time_selector_date_change}
                reportType={reportType}
                setReportType={setReportType}
                benchmarkConfigurationEnabled
                chartsSettings={this.props.chartsSettings}
                {...actionBarProps}
              />
              <Grid item xs={12} id="sticky-trigger">
                <InvestmentDetailsNavigation
                  includeHistoricalPortfolios={includeHistoricalPortfolios}
                  calculationDates={this.props.selectedDates}
                  calculationDatesType={this.props.selectedDatesType}
                  handleCalculationDatesChanged={this.props.onSelectedDatesChanged}
                  portfolios={portfolios.data}
                  portfoliosLoadingError={portfolios.errors}
                  portfoliosDataLoading={portfolios.loading}
                  handleSelectedPortfolioChanged={this.props.onSelectedPortfoliosChanged}
                  selectedPortfolios={this.props.selectedPortfolios}
                  investmentData={investmentData.data}
                  dataLoading={dataLoading}
                  dashboardSettingsLoaded={isCustomerDataMounted}
                  dispatch={this.props.dispatch}
                  customerDashboard={customerDashboard.time_selector_date_change}
                  reportType={reportType}
                  setReportType={setReportType}
                  benchmarkConfigurationEnabled
                  chartsSettings={this.props.chartsSettings}
                  onChartSettingsChange={this.props.handleChartSettingsChange}
                />
              </Grid>
            </DashboardPortfoliosSelectorSharedState>
            {!_.isEmpty(availableTabs) && (
              <Grid item xs={12}>
                <TabsContainer
                  value={this.state.activeTab}
                  loading={!isCustomerDataMounted}
                  onChange={(event, id) => this.setState({activeTab: id})}
                  tabs={Object.values(TAB).filter(tab => availableTabs.includes(tab.id))}
                  tabContent={
                    <TabsSharedData>
                      <TabComponent
                        isMounted={this.props.isMounted}
                        dashboardSettings={dashboardSettings}
                        customSettings={_.get(customSettings, `${this.state.activeTab}.subcategories`)}
                        selectedPortfolios={this.props.selectedPortfolios}
                        calculationDates={this.props.selectedDates}
                        calculationDatesType={this.props.selectedDatesType}
                        dashboardData={dashboardData.data}
                        dashboardDataLoading={dashboardData.loading}
                        dashboardDataLoadingError={dashboardData.errors}
                        instrumentListData={instrumentList.data}
                        instrumentListDataLoading={instrumentList.loading}
                        instrumentListDataLoadingError={instrumentList.errors}
                        historicalData={historicalData}
                        profitAndLossData={profitAndLoss}
                        paymentPlansData={paymentPlans.data}
                        paymentPlansLoading={paymentPlans.loading}
                        paymentPlansLoadingError={paymentPlans.errors}
                        payoutPlansData={payoutPlansData.data}
                        payoutPlansLoading={payoutPlansData.loading}
                        payoutPlansLoadingError={payoutPlansData.errors}
                        switchPlansData={switchPlansData.data}
                        switchPlansLoading={switchPlansData.loading}
                        switchPlansLoadingError={switchPlansData.errors}
                        breakdownData={breakdownData}
                        timeWeightedReturnData={timeWeightedReturnData}
                        benchmarkConfigurationEnabled
                        expandedItems={this.state.expandedItems}
                        chartsSettings={this.props.chartsSettings}
                        combinedTradings={this.props.combinedTradings}
                        onExpandedItemsChange={this.handleExpandedItemsChange}
                        onChartSettingsChange={this.props.handleChartSettingsChange}
                        onAddTradingOption={this.props.handleAddTradingOption}
                        onAddPortfolioTradingOption={this.props.handleAddPortfolioTradingOption}
                        onAddSavingPlanOption={this.props.handleAddSavingPlanOption}
                        onAddPayoutPlanOption={this.props.handleAddPayoutPlanOption}
                        onAddSwitchPlanOption={this.props.handleAddSwitchPlanOption}
                        onAddPortfolioSavingPlanOption={this.props.handleAddPortfolioSavingPlanOption}
                        onAddPortfolioPayoutPlanOption={this.props.handleAddPortfolioPayoutPlanOption}
                        onAddPortfolioSwitchPlanOption={this.props.handleAddPortfolioSwitchPlanOption}
                        onGoToTradeDetails={this.props.goToTradeDetails}
                        banksMapping={this.props.banksMappingData}
                        banksData={this.props.banksData}
                        combinedTradingSession={this.props.combinedTradingSession.data}
                        virtualInstrumentLink={customer.data && customer.data.user.agency.virtual_instrument_buy_template}
                        customerData={customer.data}
                        reportType={reportType}
                        instrumentsGroupBy={this.props.instrumentsGroupBy}
                        onInstrumentsGroupByChange={this.props.handleInstrumentsGroupByChange}
                        investmentData={investmentData}
                        portfolios={portfolios.data}
                        aggregatedPortfolioName={selectedPortfoliosName}
                      />
                    </TabsSharedData>
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} id={'sticky-nav-stoper'} />
          </Grid>
        </Container>
        <ErrorModal
          message={this.state.errorMessage}
          handleClose={this.handleErrorModalClose}
        />
        <DownloadPdfDialog
          open={this.state.pdfOpen}
          onClose={() => {this.setState({pdfOpen: false})}}
          onExport={this.fetchPdfFile}
          exportBtnDisabled={!this.props.selectedPortfolios || this.state.pdfExportBtnDisabled}
          customer={this.getCustomerData(customer.data)}
        >
          <DownloadPdfDialog.CoverPage />
          <DownloadPdfDialog.Documents />
          <DownloadPdfDialog.Expanded />
        </DownloadPdfDialog>
        <AddProductsToComparison>
          <SelectCustomerDepotsForProductsComparisonModal
            instrumentsData={this.props.instrumentList}
            open={this.state.addToComparisonModalOpen}
            onClose={this.handleAddToComparisonClose}
            customerId={_.get(customer, 'data.customer_id')}
          />
        </AddProductsToComparison>
      </React.Fragment>
    )
  }
}

const getButtonAttributes = (customer) => {
  return {
    disabled: !hasPortfolios(customer)
  }
};

let combinedTradings = {};
let banksMappingData = {};
let banksData = [];


const onCustomersChange = async (customers) => {
  if (_.isEmpty(banksMappingData)) {banksMappingData = await getBanksCompanyIdToCodeMapping();}
  if (_.isEmpty(banksData)) {
    const banksCIOSData = await QuestionnairesHandlerResource.getBanksData();
    banksData = banksCIOSData.response
  }
  combinedTradings = await onboardingOnCustomersChange(customers, OBJECT_TYPES.COMBINED);
};

// TODO: Remove not necessary code after combined trading approved
const getCombinedTradingButtonAttributes = (customer) => {
  const lastTrading = combinedTradings[customer.id];
  let lastTradingStatus = OnboardingButtonAttributesHelper.isOnboardingProcessStarted(lastTrading);
  // set button attribures
  let buttonAttrs =  OnboardingButtonAttributesHelper.getDefaultAttributes(lastTradingStatus, customer);

  // set custom click handler if order is new
  if (!buttonAttrs.disabled){
    if(!lastTradingStatus){
      buttonAttrs.customOnClickHandler = async (props) => {
        // start "new Buy" process
        let tradingsValidationData = await OnboardingButtonAttributesHelper.getCombinedTradingPortfolioValidationData(
          customer.customer_id);

        let portfolios = preparePortfoliosForCombinedTrading({
          instrumentListPortfolios: tradingsValidationData.portfolios,
          profitAndLossPortfolios: tradingsValidationData.profitAndLoss,
          savingPlansPortfolios: tradingsValidationData.payment_plans,
          payoutPlansPortfolios: tradingsValidationData.payout_plans,
          switchPlansPortfolios: tradingsValidationData.switch_plans,
          banksMappingData: banksMappingData || {},
          banksData: banksData || []
        })

        extendPortfoliosWithDataForTrading(portfolios,  banksMappingData || {}, banksData || []);

        let customerData = {customer_id: customer.customer_id};
        // dispatch signal if valid portfolios exist
        await OnboardingButtonAttributesHelper.dispatchIfPropperPortfolios(props, portfolios, customerData);
      }
    } else {
      // set custom click handler for delete btn
      buttonAttrs.customOnDeleteClickHandler = async (props) => {
        await QuestionnairesHandlerResource.deleteQuestionnaire(lastTrading.session_id);  // session_id is the same as onboarding_uid
        delete combinedTradings[customer.id];

        await OnboardingButtonAttributesHelper.dispatchRemoveTradings(props, customer.customer_id);
      }
    }
  }
  return buttonAttrs;
}

export default withCustomersSelectorNew('DASHBOARD', [getButtonAttributes, getCombinedTradingButtonAttributes], onCustomersChange)(withNotification(withStyles(styles)(connect(mapStateToProps)(withDashboardData(withTradingsData(withWidth()(CustomerDashboardV2), true), false, true)))));