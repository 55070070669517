import React from "react";
import { withStyles } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CloseIcon from '@material-ui/icons/Close';

import { toShortGermanFormat } from "../../../../utils";
import styles from "./styles";
import {FODB_CUSTODIAN_ID} from "../../../../../../utils/constants";

class CustodianWidget extends React.Component {

  constructor(props) {
    super(props);

    this.renderMark = this.renderMark.bind(this);
  }

  renderPercent(value) {
    return value === undefined || value === null ? 'k.A.' : toShortGermanFormat(value, '', ' %', 2, false)
  }

  renderAmount(value, prefix, suffix) {
    prefix = prefix || ''
    suffix = suffix || ''
    return value === undefined || value === null ? 'k.A.' : toShortGermanFormat(value, prefix + ' ', ' ' + suffix, 2, false)
  }

  renderValue(value) {
    return value === undefined || value === null ? 'k.A.' : value
  }

  renderMark(value) {
    const { classes } = this.props;
    if(value === undefined || value === null) return 'k.A.'
    return value ?
      <CheckCircleRoundedIcon htmlColor="#23d374" classes={{ root: classes.circleRoot }} /> :
      <CloseIcon htmlColor="#80858c" classes={{ root: classes.circleRoot }} />
  }

  calculateSwitchPlan(custodianInfo, assetInfo) {
    // BCA-5764
    const isinCond = !assetInfo.is_real_estate && (custodianInfo.c_company_id != FODB_CUSTODIAN_ID || assetInfo.switch_plan_company_allowed)
    return custodianInfo.switch_plan_capable && isinCond;
  }

  lines() {
    let lines = [
      { 'title': 'Ausgabeaufschlag', value: this.renderPercent(this.props.data.c_front_end_load_percent) },
      { 'title': 'Vermittlungsprovision', value: this.renderPercent(this.props.data.provision) },
      { 'title': 'Verwaltungsentgelt ex-ante', value: this.renderPercent(this.props.asset_info.product_fees_prospectus_actual_management_fee) },
      { 'title': 'Vermittlungsfolgeprovision', value: this.renderPercent(this.props.data.followup_provision) },
      { 'title': 'Mindesteinmalanlagebetrag', value: this.renderAmount(this.props.data.c_min_amount_single_payment, null, this.props.currency) },
      { 'title': 'MindestSpar-/Tauschplanrate', value: this.renderAmount(this.props.data.c_min_amount_savings_plan, null, this.props.currency) },
      { 'title': 'Maximal mögliche Rabattierung', value: this.renderPercent(this.props.data.c_front_end_load_max_discount) },

    ];
    if(this.props.data.c_payout_plan_possible)
      lines.push({ 'title': 'Mindestanlage Auszahlplan', value: this.renderAmount(this.props.data.c_min_amount_payout_plan, null, this.props.currency) },)
    return lines;
  }

  marks() {
    return [
      { 'title': 'Handelbar', value: this.props.data.c_activ },
      { 'title': 'Sparplanfähig', value: this.props.data.c_savings_plan_possible },
      { 'title': 'Kauf möglich', value: !this.props.data.c_hard_close_subscription },
      { 'title': 'Verkauf möglich', value: !this.props.data.c_hard_close_redemption },
      { 'title': 'VL Fähig', value: this.props.data.c_vl_possible },
      { 'title': 'Entnahmeplanfähig', value: this.props.data.c_payout_plan_possible },
      { 'title': 'Tauschplanfähig', value: this.calculateSwitchPlan(this.props.data, this.props.asset_info) },
    ]
  }

  renderLines(_type) {
    const { classes } = this.props;
    let lines = [];

    let data = [];
    let func = null;
    let className = ''
    if (_type === 'line') {
      data = this.lines()
      func = this.renderValue
      className = classes.itemLine
    } else if (_type === 'mark') {
      data = this.marks()
      func = this.renderMark
      className = classes.itemMark
    }

    data.forEach((item, index) => (
      lines.push(
        <li className={[classes.item, className].join(' ')} key={index}>
          <span className={classes.title}>{item.title}</span>
          <span className={classes.values}>{func(item.value)}</span>
        </li>
      )
    ))
    return lines;
  }


  renderData() {
    const { classes, data } = this.props;

    return (
      <>
        <div className={classes.leftPart}>
          <ul className={classes.list}>
            {this.renderLines('line')}
          </ul>
        </div>
        <div className={classes.rightPart}>
          <ul className={classes.list}>
            {this.renderLines('mark')}
          </ul>
        </div>
      </>
    )
  }

  render() {
    const { classes, data, expanded } = this.props;

    return (
      <Accordion classes={{ root: classes.root }} defaultExpanded={expanded}>
        <AccordionSummary 
          classes={{ root: classes.panelTitle, expanded: classes.panelTitleExpanded }}
          expandIcon={<ExpandMoreIcon fontSize="large" htmlColor="#202a38"/>}
        >
          <span className="custodian-name">{data.c_custodian}</span>
          <i className="left-border"/>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.detailsRoot }}>
          {this.renderData()}
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default withStyles(styles)(CustodianWidget)
