import React from 'react';
import _ from "lodash";

import ChartSectionBordered from "../../../../../components_v2/ChartSectionBordered/ChartSectionBordered";
import {StructureTable} from "../../../../../components_v2/StructureTable/StructureTable";
import {
  CustomerDashboardNoDataPlaceholder
} from "../../../../../../FactSheetsPage/components/NoDataPlaceholder";
import {portfolioStructurePropsAreEquals} from '../utils';


const TotalAssetVolume = React.memo((props) => {
  const {
    dashboardData,
    expanded,
    onExpanded,
  } = props;

  const data = dashboardData && dashboardData.portfolio_structure_volume && dashboardData.portfolio_structure_volume.total_assets;
  const hasData = !_.isEmpty(data);

  return (
    <ChartSectionBordered
      title={<b>Vermögensaufteilung</b>}
      expanded={expanded}
      onExpanded={onExpanded}
      skipContentPadding={hasData}
      content={(
        <>
          {hasData ? (
            <StructureTable
              series={data || []}
              useSeriesColor={true}
            />
          ) : (
            <CustomerDashboardNoDataPlaceholder />
          )}
        </>
      )}
    />
  )
}, portfolioStructurePropsAreEquals);

export default TotalAssetVolume;
