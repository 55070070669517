import React from "react";
import _ from "lodash";

import {withWidth} from "@material-ui/core";
import mapData from '@highcharts/map-collection/custom/world.geo.json'
// import clsx from "clsx";

import {
  allocationErrors, getSeriesFromValues,
} from "./helpers";
import HighchartsBase from "../../../../components/Charts/Base";
import { getTranslates } from '../../../../utils/utils';
import { toGermanFormat } from '../../../../utils/numberFormater';


function lessThanZero(value) {
  const threshold = 0.01;
  if (value === 0 || value > threshold) {
    return toGermanFormat(value)
  }
  return `<${toGermanFormat(threshold)}`;
}

function RegionChart(props) {
  const { 
    data,
    height,
    width,
    byCountry,
  } = props;
  const errorsInData = allocationErrors(data);
  const series = getSeriesFromValues(data, props);
  const isMobile = ["xs", "sm"].includes(width);
  const [countriesTranslates,] = React.useState(getTranslates('countries'));

  const byCountryDict = React.useMemo(() => {
    const val = {};
    (byCountry || []).forEach(c => val[c.code] = c.weight * 100);

    return val;
  }, [byCountry]);

  const getCountryName = (name) => {
    return _.get(countriesTranslates, [name], name);
  };

  // NOTE: we need to ovverride exactly the same fields as they are "cashed" each render
  const legendOptions = isMobile ? {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
    itemStyle: {
      cursor: props.disableLegendClick ? 'default' : 'pointer',
      fontSize: '12px',
      width: '180px',
      lineHeight: '17px',
    },
    labelFormatter: function() {
      const value = (this.valueData && this.valueData[0]) ? this.valueData[0] : 0
      return `
        <span style="
          position: relative;
          top: 3px;
          display: inline-block;
          width: 180px;
        ">
          <b>${this.name}</b>
          <span style="
            position: absolute;
            right: 0;
            font-weight: normal;
          ">${lessThanZero(value)} %</span>
        <span>
      `;
    },
    itemMarginBottom: 0,
    y: 0,
    symbolPadding: 0,
  } : {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    itemStyle: {
      cursor: props.disableLegendClick ? 'default' : 'pointer',
      fontSize: '14px',
      width: '300px',
      lineHeight: '21px',
    },
    labelFormatter: function() {
      const value = (this.valueData && this.valueData[0]) ? this.valueData[0] : 0
      return `
        <span style="
          position: relative;
          top: 3px;
          display: inline-block;
          width: 250px;
        ">
          <b>${this.name}</b>
          <span style="
            position: absolute;
            right: 0;
            font-weight: normal;
          ">${lessThanZero(value)} %</span>
        <span>
      `;
    },
    itemMarginBottom: 13,
    y: 38,
    symbolPadding: 8,
  };

  const options = {
    series,
    chart: {
      map: mapData,
      height: height || 400,
      style: {
        fontFamily: '"Roboto-Regular", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
      },
    },
    tooltip: {
      pointFormatter: function() {
        let defaultValue = _.isNil(byCountry) ? this.value : 0;
        return `${getCountryName(this.name)}: <b>${lessThanZero(_.get(byCountryDict, this.code, defaultValue))} %`
      },
    },
    title: null,
    credits: {
      enabled: false
    },
    legend: Object.assign({
      enabled: true,
      useHTML: true,
      navigation: {
        enabled: false
      },
      squareSymbol: true,
      symbolHeight: 6,
      symbolRadius: '50%',
    }, legendOptions),
    plotOptions: {
      map: {
        allAreas: false,
      },
    },
  }

  return (
    <HighchartsBase
      language={'DE'}
      options={options}
      constructorType='mapChart'
      callback={(chart) => {
        // max height should be the container size minus the Title + InfoTooltip height.
        chart.container.parentElement.style.maxHeight = 'calc(100% - 55px)'
        chart.reflow()
      }}
    />
  )
}

export default withWidth()(RegionChart)
