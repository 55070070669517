import { 
  regions,
  countries,
  nearEastCodes,
  latinAmericanAndCaribbeanCodes,
} from "./constants";

export function allocationErrors(data) {
  if (!data || !data.by_component) {
    return [];
  }
  return data['by_component'].filter((item) => {
    if (item.error) {
      return item;
    }
  });
}

export function getValuesByRegion(values) {
  const validCodes = []
  const valuesByRegion = values.reduce((acc, region) => {
    const code = region.code || 'OTHERS'
    validCodes.push(code)
    acc[code] = {
      name: region.name,
      code: region.code,
      marketValue: region.market_value,
      weight: region.weight * 100
    }
    return acc
  }, {})

  return {
    valuesByRegion,
    validCodes
  }
}

function getDataForAreas(valuesForRegions) {
  return countries.reduce((acc, country) => {
    let regionCode = 'OTHERS'
    if (country.continent === 'AF' || nearEastCodes.includes(country.code)) {
      // Africa and Near East
      regionCode = 'AF'
      acc.AF.push(country)
    } else if (country.continent === 'NA' && !latinAmericanAndCaribbeanCodes.includes(country.code) && country.code !== 'GL') {
      // North America (not Greenland)
      regionCode = 'AM'
      acc.AM.push(country)
    } else if (country.continent === 'AS' || country.continent === 'OC' || country.code === 'RU') {
      // Asia-Pacific + Rusia
      regionCode = 'AS'
      acc.AS.push(country)
    } else if (country.continent === 'SA' || latinAmericanAndCaribbeanCodes.includes(country.code)) {
      // Latin America
      regionCode = 'LA'
      acc.LA.push(country)
    } else if (country.continent === 'EU' || country.code === 'GL') {
      // Europe
      regionCode = 'EU'
      acc.EU.push(country)
    } else {
      // Others
      acc.OTHERS.push(country)
    }

    country.value = valuesForRegions[regionCode].weight
    country.continentName = valuesForRegions[regionCode].name

    return acc
  }, {
    AF: [],
    AM: [],
    AS: [],
    EU: [],
    LA: [],
    OTHERS: []
  })
}

export function getCountriesByRegion(valuesForRegions) {
  regions.forEach((region) => {
    const code = region.code

    if (!valuesForRegions[code]) {
      valuesForRegions[code] = {
        name: region.name,
        marketValue: 0,
        weight: 0,
        code
      }
    }
  })

  return getDataForAreas(valuesForRegions);
}

export function hexToRGB(color, options = {}) {
  if (!options.opacity) options.opacity = 1
  const { opacity } = options
  const r = parseInt(color.substring(1, 3), 16)
  const g = parseInt(color.substring(3, 5), 16)
  const b = parseInt(color.substring(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}


export function getSeriesFromValues(values, options) {
  const {
    hideZeros,
    useOpacity,
    colors,
    useBubbles,
    fixedSize
  } = options

  const { valuesByRegion, validCodes } = getValuesByRegion(values)
  // get list of countries and set the correct `value` property for each country
  
  const countriesByRegion = getCountriesByRegion(valuesByRegion, options)

  const weights = []
  // get a sorted array of region codes and their weights, so opacity can be
  // applied
  Object.keys(countriesByRegion).forEach((regionCode) => {
    weights.push({
      code: regionCode,
      weight: useBubbles ? countriesByRegion[regionCode][0].z : countriesByRegion[regionCode][0].value
    })
  })
  weights.sort((a, b) => b.weight - a.weight)

  /**
   * Finally go through the sorted elements and add the series to the final result.
   *  - Color is added here depending on `useOpacity`.
   *  - With this method, the legend will also appear sorted
   *  - If `hideZeros` is `true`, series will be added only when the data exists
   *    for the given region code.
   */
  let opacity = 1

  const series = [{
    allAreas: true,
    showInLegend: false
  }]

  weights.forEach((elem, idx) => {
    const regionCode = elem.code
    const weight = elem.weight
    const valueIsZero = weight === 0
    const showCode = validCodes.includes(regionCode)

    if (!hideZeros || (showCode && !valueIsZero)) {
      const serie = {
        type: useBubbles ? 'mapbubble' : 'map',
        data: countriesByRegion[regionCode],
        joinBy: ['iso-a2', 'code'],
        name: countriesByRegion[regionCode][0].continentName,
        borderWidth: 0.1,
        minSize: fixedSize ? '25%' : '0%',
        maxSize: fixedSize ? '25%' : '30%'
      }
      if (colors[idx]) {
        serie.color = hexToRGB(colors[idx], { opacity });
      }
      series.push(serie)
      opacity = useOpacity ? opacity - 0.15 : opacity
    }
  });

  return series
}