import React from 'react';
import _ from "lodash";

import {Grid} from '@material-ui/core'

import InstrumentListItem from './components/InstrumentListItem';
import { getPortfolioTradings } from '../../../../../../components/TradingStore/utils'
import {GROUP_ACTION_DEPOT} from "../../../../../../components/Charts/InstrumentsAllocationTable/constants";

export default (props) => {
  const {
    data,
    virtualInstrumentLink,
    expandedItems,
    onExpandedItemsChange,
    isCustomerApp,
    onAddTradingOption,
    onAddPortfolioTradingOption,
    tradings,
    banksMapping,
    tradingSession,
    reportType,
    isVirtual,
    triggerPortfolioDeleting,
    refresh,
    customerData,
    canTradePortfolios,
    instrumentsGroupBy,
    unrealizedProfitAndLossData,
    withTransactionSaldo,
    withCompletelySoldAssets,
    portfolioNameRender,
    showSubSectionTitle,
    footerText,
    completelySoldAssetsData,
    showAssetsOverview = true,
} = props;

  const renderPortfolio = (portfolio) => {
    return (
      <Grid item xs={12}>
        <InstrumentListItem
          key={portfolio.id}
          portfolio={portfolio}
          virtualInstrumentLink={virtualInstrumentLink}
          expandedItems={expandedItems}
          onExpanded={onExpandedItemsChange}
          isCustomerApp={isCustomerApp}
          isVirtualOrderEnabled={props.isVirtualOrderEnabled}
          onAddTradingOption={onAddTradingOption}
          onAddPortfolioTradingOption={onAddPortfolioTradingOption}
          tradings={getPortfolioTradings(tradings, portfolio.id)}
          banksMapping={banksMapping}
          tradeTransactions={tradingSession}
          reportType={reportType}
          isVirtual={isVirtual}
          triggerPortfolioDeleting={triggerPortfolioDeleting}
          refresh={refresh}
          customerData={customerData}
          instrumentsGroupBy={instrumentsGroupBy}
          canTradePortfolios={canTradePortfolios}
          showCell={props.showCell}
          withTransactionSaldo={withTransactionSaldo}
          withCompletelySoldAssets={withCompletelySoldAssets}
          unrealizedProfitAndLossData={unrealizedProfitAndLossData}
          resource={props.resource}
          portfolioNameRender={portfolioNameRender}
          showSubSectionTitle={showSubSectionTitle}
          footerText={footerText}
          completelySoldAssetsData={completelySoldAssetsData}
          showAssetsOverview={showAssetsOverview}
        />
      </Grid>
    )
  };

  const renderActivePortfolios = (portfolios) => {
    return portfolios.map((portfolio) => {
      return renderPortfolio(portfolio);
    });
  };

  const renderEmptyPortfolios = (empty) => {
    const portfolios = empty
      .filter(portfolio => !portfolio.is_historical || portfolio.hasCompletelySoldData || (portfolio.clearing_account && portfolio.clearing_account.length));

    return portfolios.map((portfolio) => {
      return renderPortfolio(portfolio);
    });
  };

  const renderData = () => {
    let active = [];
    let empty = [];

    const portfoliosIds = [];

    data && data.forEach(portfolio => {
      (portfolio.is_empty ? empty : active).push(portfolio)
      portfoliosIds.push(portfolio.id);
    });

    if (props.instrumentsGroupBy == GROUP_ACTION_DEPOT) {
      // Completely sold portfolios without portfolio in instrument table
      const completelySoldPortfolios = (completelySoldAssetsData.data || []).filter((portfolio) => {
        return !portfoliosIds.includes(portfolio.id);
      });
      if (!_.isEmpty(completelySoldPortfolios)) {
        empty = [...empty, ...completelySoldPortfolios.map((portfolio) => ({
          ...portfolio,
          // FIXME: workaround until we have old dashbaord and can't change structure on backend
          hasCompletelySoldData: true,
          components: [
            {
              'id': 'completely_sold',
              'name': 'Vollständig verkaufte Vermögenswerte',
              'market_value': null,
              'invested_amount': portfolio.invested_amount || 0,
              'profit_loss_eur': portfolio.profit_loss_eur || 0,
              'profit_loss_perc': portfolio.profit_loss_perc || 0,
              'profit_loss_percentage_pa': portfolio.profit_loss_percentage_pa || 0,
              'value_start': portfolio.profit_value_start || 0,
              'is_profit_loss': true,  // needed not to count profit_loss_components when p.a. warning is set
              'sub_components': portfolio.components
            }
          ]
        }))]
      }
    }

    const emptyExist = empty.length > 0;
    return (
      <Grid container spacing={2}>
        {renderActivePortfolios(active)}
        {emptyExist && renderEmptyPortfolios(empty)}
      </Grid>
    );
  };

  return (
    <>
      {renderData()}
    </>
  )
}