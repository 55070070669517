import React from 'react';
import _ from "lodash";

import { Grid } from '@material-ui/core';

import useStyles from '../styles';
import NonFieldErrors from "../NonFieldErrors";
import InputFormElement from "../../../../formElement/InputFormElement";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {isValidString} from "../../../../../../../utils";

const IBAN_MESSAGES = {
  "Cannot parse as IBAN: Invalid / no check digits found": "Diese IBAN-Nummer ist ungültig, bitte überprüfen Sie die Nummer.",
  "Cannot parse as IBAN: IBAN length invalid": "Diese IBAN-Nummer ist ungültig, bitte überprüfen Sie die Nummer.",
  "Cannot parse as IBAN: Invalid country code": "Kann nicht als IBAN analysiert werden: Ungültiger Ländercode",
  "Validation failed": "IBAN Überprüfung fehlgeschlagen",
  "Cannot validate bank code length": "Die Länge der Bankleitzahl kann nicht überprüft werden.",
  "Cannot get BIC": "BIC kann nicht abgerufen werden.",
  "Invalid bank code": "Diese Bankleitzahl ist ungültig, bitte überprüfen Sie die Nummer.",
  "No BIC found for bank code": "Diese Bankleitzahl ist ungültig, bitte überprüfen Sie die Nummer.",
  "Bank code valid": ""
};

const translate = (msg) => {
  const key = Object.keys(IBAN_MESSAGES).find(i => _.startsWith(msg, i));
  return key ? IBAN_MESSAGES[key] : msg;
};

export default (props) => {
  const classes = useStyles();

  const {
    handleFormChange,
    errors,
    setErrors,
    instance,
    viewOnly
  } = props;

  const [model, setModel] = React.useState({
    accountHolder: '',
    iban: '',
    bic: '',
    name: '',
    city: '',
  });
  const [ibanSearchDone, setIbanSearchDone] = React.useState(false);

  React.useEffect(() => {
    if (instance) {
      const _model = {
        accountHolder: instance.accountHolder,
        iban: instance.iban,
        bic: instance.bic,
        name: instance.name,
        city: instance.city,
      };
      setModel(_model);
    }
  }, [instance]);


  const prepareFormData = () => {
    return {
      accountHolder: model.accountHolder,
      iban: model.iban,
      bic: model.bic,
      name: model.name,
      city: model.city,
    }
  };

  const handleSearchIBAN = () => {
    if(isValidString(model.iban)) {
      axios.request({
        url: 'https://openiban.com/validate/{IBAN_NUMBER}'.replace('{IBAN_NUMBER}', model.iban),
        params: {getBIC: true, validateBankCode: true}
      })
        .then((result) => {
          const bankData = result.data.bankData || {};
          setModel({
            ...model,
            bic: bankData.bic || "",
            name: bankData.name || "",
            city: bankData.city || ""
          });

          setErrors({
            ...errors,
            iban: result.data.valid ? '' : _.isArray(result.data.messages) && _.join(_.uniq(
              result.data.messages.map(msg => translate(msg)).filter(msg => !!msg)), '\n')
          });
        })
        .catch((err) => {
          console.log(err);
        }).finally(() => setIbanSearchDone(true));
    }
  };

  React.useEffect(() => {
    handleFormChange && handleFormChange(prepareFormData());
  }, [model]);

  const handleModelChange = (field) => (value) => {
    setModel({
      ...model,
      [field]: value
    })
    setErrors({
      ...errors,
      [field]: undefined
    })
  };

  const SearchButton = () => (
    <Button disabled={!isValidString(model.iban)} onClick={handleSearchIBAN} color="primary" variant="contained"
            className={classes.searchButton}>
      Weiter
    </Button>
  );

  return (
    <Grid container className={classes.formContainer}>
      <Grid item className={classes.formBody}>
        <div>
          <div className={classes.stepRequired}>
            *Pflichtfeld
          </div>
          <InputFormElement
            label={"Kontoinhaber"}
            error={errors && errors.accountHolder}
            value={model.accountHolder}
            onChange={handleModelChange('accountHolder')}
            disabled={viewOnly}
            custom_classes={{'labelRoot': 'bold'}}
            required={true}
          />
          <InputFormElement
            label={"IBAN"}
            error={errors && errors.iban}
            value={model.iban}
            onChange={handleModelChange('iban')}
            disabled={viewOnly}
            custom_classes={{'labelRoot': 'bold'}}
            required={true}
            inputProps={{endAdornment: <SearchButton />}}
          />
          <InputFormElement
            label={"BIC"}
            error={errors && errors.bic}
            value={model.bic}
            onChange={handleModelChange('bic')}
            disabled={viewOnly || !ibanSearchDone}
            custom_classes={{'labelRoot': 'bold'}}
            required={true}
          />
          <InputFormElement
            label={"Name des Kreditinstitutes"}
            error={errors && errors.name}
            value={model.name}
            onChange={handleModelChange('name')}
            disabled={viewOnly || !ibanSearchDone}
            custom_classes={{'labelRoot': 'bold'}}
            required={true}
          />
          <InputFormElement
            label={"Ort der Kreditinstitutes"}
            error={errors && errors.city}
            value={model.city}
            onChange={handleModelChange('city')}
            disabled={viewOnly || !ibanSearchDone}
            custom_classes={{'labelRoot': 'bold'}}
          />
          {errors && errors['non_field_errors'] && <NonFieldErrors errors={errors['non_field_errors']}/>}
          {errors && errors['detail'] && <NonFieldErrors errors={[errors['detail']]}/>}
        </div>
      </Grid>
    </Grid>
  )
}