import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import styles from "./styles";
import { FactSheetsHandlerResource } from "../../../../utils/api";
import BaseChart from '../../components/BaseChart';
import { processResponse } from "../../index";
import _ from 'lodash';
import { OTHER_TYPE_TRANSLATION } from "../../../../utils/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import {sortByWeight} from "../../utils";

export function translateBarChartLabels(dataArray, dictionary=OTHER_TYPE_TRANSLATION) {
  return dataArray.map(item => {
    item.name = item.name_de || dictionary[item.name] || item.name;
    return item;
  })
}

export class BaseStructureTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataProvider: null,
      allocationData: null,
      allocationLoading: true,
      allocationError: null,
      sizes: null
    }
    this.otherName = 'Sonstige'
    this.calculateSonstige = true
    this.hideCharts = {};
    this.getAllocation = this.getAllocation.bind(this);
    this.translateBarChartLabels = translateBarChartLabels;
  }

  componentDidMount() {
    this.getAllocation()
  }

  getAllocation() {
    throw Error("You should override it");
  }

  getDataByKey(key) {
    const { allocationData } = this.state;
    let result = [];

    if (allocationData && !this.hideCharts[key]) {
      result = allocationData[key];
    }

    return result;
  }

  getSectionSize(key, defaultSize) {
    return this.state.sizes && this.state.sizes[key] || defaultSize;
  }

  render() {
    return <>
      <Grid item xs={12} sm={6} md={4}>
        <BaseChart
          chartType='bar'
          title={'Assetklassen'}
          seriesData={
            this.translateBarChartLabels(
                sortByWeight(this.getDataByKey('by_type'), this.calculateSonstige)
            )
          }
          loading={this.state.allocationLoading}
          error={this.state.allocationError}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <BaseChart
          chartType='bar'
          title={'Währungen'}
          seriesData={
            this.translateBarChartLabels(
                sortByWeight(this.getDataByKey('by_currency'), this.calculateSonstige)
            )
          }
          loading={this.state.allocationLoading}
          error={this.state.allocationError}
          allowFloatingMixMax={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <BaseChart
          chartType='bar'
          title={'Branchen'}
          seriesData={
            this.translateBarChartLabels(
                sortByWeight(this.getDataByKey('by_sector'), this.calculateSonstige)
            )
          }
          loading={this.state.allocationLoading}
          error={this.state.allocationError}
        />
      </Grid>
      {
        this.getDataByKey('by_maturity').length>0 && (
          <Grid item xs={12} sm={6} md={4}>
            <BaseChart
              chartType='bar'
              title={'Restlaufzeiten'}
              seriesData={this.translateBarChartLabels(sortByWeight(
                this.getDataByKey('by_maturity'),
                this.calculateSonstige,
                true
                )
              )}
              loading={this.state.allocationLoading}
              error={this.state.allocationError}
            />
          </Grid>
        )
      }
      {
        this.getDataByKey('by_credit_quality').length>0  && (
          <Grid item xs={12} sm={6} md={4}>
            <BaseChart
              chartType='bar'
              title={'Kreditqualitäten der Emittenten'}
              seriesData={this.translateBarChartLabels(sortByWeight(
                  this.getDataByKey('by_credit_quality'),
                  this.calculateSonstige,
                  true
                )
              )}
              loading={this.state.allocationLoading}
              error={this.state.allocationError}
            />
          </Grid>
        )
      }
      {
        this.getDataByKey('by_subtype').length>0 && (
          <Grid item xs={12} sm={6} md={4}>
            <BaseChart
              chartType='bar'
              title={'Emittentenstruktur'}
              seriesData={
                this.translateBarChartLabels(
                  sortByWeight(this.getDataByKey('by_subtype'), this.calculateSonstige)
                )
              }
              loading={this.state.allocationLoading}
              error={this.state.allocationError}
            />
          </Grid>
        )
      }
      <Grid item xs={12} md={this.getSectionSize('region', 12)}>
        <BaseChart
          chartType='region'
          title={'Regionen'}
          seriesData={sortByWeight(this.getDataByKey('by_region'))}
          extraData={this.getDataByKey('by_country')}
          loading={this.state.allocationLoading}
          error={this.state.allocationError}
          height={this.props.regionHeight}
        />
      </Grid>
    </>
  }
}

class FundStructureTab extends BaseStructureTab {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      dataProvider: FactSheetsHandlerResource,
      sizes: {region: 8}
    }
    this.topHoldings = (this.props.data.top_holdings || []);

  }

  getAllocation() {
    processResponse(
      this.state.dataProvider.getAllocationData(this.props.data.isin),
      'allocation', 'allocation', this
    )
  }

  renderError() {
    return <p>{'Fehler. Die "Fondsstruktur" konnte nicht geladen werden.'}</p>
  }

  renderCharts() {
    return <>
      {this.getDataByKey('by_type').length>0 && (
        <Grid item xs={12} sm={6} md={4}>
          <BaseChart
            chartType='bar'
            title={'Assetallokation'}
            seriesData={
              this.translateBarChartLabels(
                  sortByWeight(this.getDataByKey('by_type'), this.calculateSonstige)
              )
            }
            loading={this.state.allocationLoading}
            error={this.state.allocationError}
          />
        </Grid>
      )}

      {this.getDataByKey('by_currency').length>0 && (
        <Grid item xs={12} sm={6} md={4}>
          <BaseChart
            chartType='bar'
            title={'Währungen'}
            seriesData={
              this.translateBarChartLabels(
                  sortByWeight(this.getDataByKey('by_currency'), this.calculateSonstige)
              )
            }
            loading={this.state.allocationLoading}
            error={this.state.allocationError}
            allowFloatingMixMax={true}
          />
        </Grid>
      )}

      {!_.isEmpty(this.topHoldings) && (
        <Grid item xs={12} md={4}>
          <BaseChart
            chartType='table'
            title={'Top 10 Positionen'}
            seriesData={this.topHoldings}
          />
        </Grid>
      )}

      {this.getDataByKey('by_region').length>0 && (
        <Grid item xs={this.getSectionSize('region', 12)}>
          <BaseChart
            chartType='region'
            title={'Regionen'}
            height={350}
            seriesData={sortByWeight(this.getDataByKey('by_region'))}
            extraData={this.getDataByKey('by_country')}
            loading={this.state.allocationLoading}
            error={this.state.allocationError}
          />
        </Grid>
      )}

      {this.getDataByKey('by_sector').length>0 && (
        <Grid item xs={12} sm={6} md={4}>
          <BaseChart
            chartType='bar'
            title={'Branchen'}
            seriesData={
              this.translateBarChartLabels(
                  sortByWeight(this.getDataByKey('by_sector'), this.calculateSonstige)
              )
            }
            loading={this.state.allocationLoading}
            error={this.state.allocationError}
          />
        </Grid>
      )}

      {this.getDataByKey('by_maturity').length>0 && (
        <Grid item xs={12} sm={6} md={4}>
          <BaseChart
            chartType='bar'
            title={'Restlaufzeiten'}
            seriesData={this.translateBarChartLabels(sortByWeight(
              this.getDataByKey('by_maturity'),
              this.calculateSonstige,
              true
              )
            )}
            loading={this.state.allocationLoading}
            error={this.state.allocationError}
          />
        </Grid>
      )}

      {this.getDataByKey('by_credit_quality').length>0 && (
        <Grid item xs={12} sm={6} md={4}>
          <BaseChart
            chartType='bar'
            title={'Kreditqualitäten der Emittenten'}
            seriesData={this.translateBarChartLabels(sortByWeight(
              this.getDataByKey('by_credit_quality'),
              this.calculateSonstige,
              true
              )
            )}
            loading={this.state.allocationLoading}
            error={this.state.allocationError}
          />
        </Grid>
      )}

      {this.getDataByKey('by_subtype').length>0 && (
        <Grid item xs={12} sm={6} md={4}>
          <BaseChart
            chartType='bar'
            title={'Emittentenstruktur'}
            seriesData={
              this.translateBarChartLabels(
                sortByWeight(this.getDataByKey('by_subtype'), this.calculateSonstige)
              )
            }
            loading={this.state.allocationLoading}
            error={this.state.allocationError}
          />
        </Grid>
      )}

    </>
  }

  render() {
    return <Grid container spacing={2}>
       {
          this.state.allocationLoading
            ? <CircularProgress />
            : (this.state.allocationError && !this.topHoldings.length) ? this.renderError() : this.renderCharts()
       }
    </Grid>
  }
}

export default withStyles(styles)(FundStructureTab);
