export const regions = [
  {
    name: 'Afrika und naher Osten',
    code: 'AF',
    bubbleCountryCode: 'SD' // Sudan
  },
  {
    name: 'Nordamerika',
    code: 'AM',
    bubbleCountryCode: 'US' // United States
  },
  {
    name: 'Asien',
    code: 'AS',
    bubbleCountryCode: 'MM' // Myanmar
  },
  {
    name: 'Europa',
    code: 'EU',
    bubbleCountryCode: 'AT' // Austria
  },
  {
    name: 'Zentral- und Südamerika',
    code: 'LA',
    bubbleCountryCode: 'CO' // Colombia
  },
  {
    name: 'Sonstige',
    code: 'OTHERS',
    bubbleCountryCode: 'GS' // South Georgia and the South Sandwich Islands
  }
];

export const nearEastCodes = [
  'AF', // Afghanistan
  'DZ', // Algeria
  'BH', // Bahrain
  'CY', // Cyprus
  'EG', // Egypt
  'IR', // Iran
  'IQ', // Iraq
  'IL', // Israel
  'JO', // Jordan
  'KW', // Kuwait
  'LB', // Lebanon
  'LY', // Libya
  'MR', // Mauritania
  'MA', // Morocco
  'OM', // Oman
  'PS', // Palestinian territories
  'PK', // Pakistan
  'QA', // Qatar
  'SA', // Saudi Arabia
  'SY', // Syria
  'TN', // Tunisia
  'TR', // Turkey
  'AE', // United Arab Emirates
  'YE', // Yemen
  'SX' // Somaliland (St. Marteen)
];

export const latinAmericanAndCaribbeanCodes = [
  'AG', // Antigua & Barbuda
  'AI', // Anguilla
  'AW', // Aruba
  'BS', // Bahamas
  'BB', // Barbados
  'BM', // Bermuda
  'BQ', // Bonaire
  'KY', // Cayman Islands
  'CU', // Cuba
  'CW', // Curacao
  'DM', // Dominica
  'DO', // Dominican Republic
  'GD', // Grenada
  'GP', // Guadeloupe
  'HT', // Haiti
  'JM', // Jamaica
  'MQ', // Martinique
  'MS', // Montserrat
  'PR', // Puerto Rico
  'BL', // Saint Barthélemy
  'KN', // St. Kitts & Nevis
  'LC', // St. Lucia
  'MF', // St. Martin
  'PM', // St. Pierre and Miquelon
  'VC', // St. Vincent and the Grenadines
  'TT', // Trinidad & Tobago
  'TC', // Turks & Caicos Islands
  'VG', // British Virgin Islands
  'VI', // US Virgin Islands
  'BZ', // Belize
  'CR', // Costa Rica
  'SV', // El Salvador
  'GT', // Guatemala
  'HN', // Honduras
  'MX', // Mexico
  'NI', // Nicaragua
  'PA', // Panama
  'AR', // Argentina
  'BO', // Bolivia
  'BR', // Brazil
  'CL', // Chile
  'CO', // Colombia
  'EC', // Ecuador
  'GF', // French Guiana
  'GY', // Guyana
  'PY', // Paraguay
  'PE', // Peru
  'SR', // Suriname
  'UY', // Uruguay
  'VE', // Venezuela
  'FK' // Falkland Islands
]


export const countries = [
  {
    capital: "Andorra la Vella",
    code: "AD",
    continent: "EU",
    currency: "EUR",
    name: "Andorra",
    phone: "376"
  },
  {
    capital: "Abu Dhabi",
    code: "AE",
    continent: "AS",
    currency: "AED",
    name: "United Arab Emirates",
    phone: "971"
  },
  {
    capital: "Kabul",
    code: "AF",
    continent: "AS",
    currency: "AFN",
    name: "Afghanistan",
    phone: "93"
  },
  {
    capital: "Saint John's",
    code: "AG",
    continent: "NA",
    currency: "XCD",
    name: "Antigua and Barbuda",
    phone: "1268"
  },
  {
    capital: "The Valley",
    code: "AI",
    continent: "NA",
    currency: "XCD",
    name: "Anguilla",
    phone: "1264"
  },
  {
    capital: "Tirana",
    code: "AL",
    continent: "EU",
    currency: "ALL",
    name: "Albania",
    phone: "355"
  },
  {
    capital: "Yerevan",
    code: "AM",
    continent: "AS",
    currency: "AMD",
    name: "Armenia",
    phone: "374"
  },
  {
    capital: "Luanda",
    code: "AO",
    continent: "AF",
    currency: "AOA",
    name: "Angola",
    phone: "244"
  },
  {
    capital: "",
    code: "AQ",
    continent: "AN",
    currency: "",
    name: "Antarctica",
    phone: "672"
  },
  {
    capital: "Buenos Aires",
    code: "AR",
    continent: "SA",
    currency: "ARS",
    name: "Argentina",
    phone: "54"
  },
  {
    capital: "Pago Pago",
    code: "AS",
    continent: "OC",
    currency: "USD",
    name: "American Samoa",
    phone: "1684"
  },
  {
    capital: "Vienna",
    code: "AT",
    continent: "EU",
    currency: "EUR",
    name: "Austria",
    phone: "43"
  },
  {
    capital: "Canberra",
    code: "AU",
    continent: "OC",
    currency: "AUD",
    name: "Australia",
    phone: "61"
  },
  {
    capital: "Oranjestad",
    code: "AW",
    continent: "NA",
    currency: "AWG",
    name: "Aruba",
    phone: "297"
  },
  {
    capital: "Mariehamn",
    code: "AX",
    continent: "EU",
    currency: "EUR",
    name: "Åland",
    phone: "358"
  },
  {
    capital: "Baku",
    code: "AZ",
    continent: "AS",
    currency: "AZN",
    name: "Azerbaijan",
    phone: "994"
  },
  {
    capital: "Sarajevo",
    code: "BA",
    continent: "EU",
    currency: "BAM",
    name: "Bosnia and Herzegovina",
    phone: "387"
  },
  {
    capital: "Bridgetown",
    code: "BB",
    continent: "NA",
    currency: "BBD",
    name: "Barbados",
    phone: "1246"
  },
  {
    capital: "Dhaka",
    code: "BD",
    continent: "AS",
    currency: "BDT",
    name: "Bangladesh",
    phone: "880"
  },
  {
    capital: "Brussels",
    code: "BE",
    continent: "EU",
    currency: "EUR",
    name: "Belgium",
    phone: "32"
  },
  {
    capital: "Ouagadougou",
    code: "BF",
    continent: "AF",
    currency: "XOF",
    name: "Burkina Faso",
    phone: "226"
  },
  {
    capital: "Sofia",
    code: "BG",
    continent: "EU",
    currency: "BGN",
    name: "Bulgaria",
    phone: "359"
  },
  {
    capital: "Manama",
    code: "BH",
    continent: "AS",
    currency: "BHD",
    name: "Bahrain",
    phone: "973"
  },
  {
    capital: "Bujumbura",
    code: "BI",
    continent: "AF",
    currency: "BIF",
    name: "Burundi",
    phone: "257"
  },
  {
    capital: "Porto-Novo",
    code: "BJ",
    continent: "AF",
    currency: "XOF",
    name: "Benin",
    phone: "229"
  },
  {
    capital: "Gustavia",
    code: "BL",
    continent: "NA",
    currency: "EUR",
    name: "Saint Barthélemy",
    phone: "590"
  },
  {
    capital: "Hamilton",
    code: "BM",
    continent: "NA",
    currency: "BMD",
    name: "Bermuda",
    phone: "1441"
  },
  {
    capital: "Bandar Seri Begawan",
    code: "BN",
    continent: "AS",
    currency: "BND",
    name: "Brunei",
    phone: "673"
  },
  {
    capital: "Sucre",
    code: "BO",
    continent: "SA",
    currency: "BOB,BOV",
    name: "Bolivia",
    phone: "591"
  },
  {
    capital: "Kralendijk",
    code: "BQ",
    continent: "NA",
    currency: "USD",
    name: "Bonaire",
    phone: "5997"
  },
  {
    capital: "Brasília",
    code: "BR",
    continent: "SA",
    currency: "BRL",
    name: "Brazil",
    phone: "55"
  },
  {
    capital: "Nassau",
    code: "BS",
    continent: "NA",
    currency: "BSD",
    name: "Bahamas",
    phone: "1242"
  },
  {
    capital: "Thimphu",
    code: "BT",
    continent: "AS",
    currency: "BTN,INR",
    name: "Bhutan",
    phone: "975"
  },
  {
    capital: "",
    code: "BV",
    continent: "AN",
    currency: "NOK",
    name: "Bouvet Island",
    phone: "47"
  },
  {
    capital: "Gaborone",
    code: "BW",
    continent: "AF",
    currency: "BWP",
    name: "Botswana",
    phone: "267"
  },
  {
    capital: "Minsk",
    code: "BY",
    continent: "EU",
    currency: "BYR",
    name: "Belarus",
    phone: "375"
  },
  {
    capital: "Belmopan",
    code: "BZ",
    continent: "NA",
    currency: "BZD",
    name: "Belize",
    phone: "501"
  },
  {
    capital: "Ottawa",
    code: "CA",
    continent: "NA",
    currency: "CAD",
    name: "Canada",
    phone: "1"
  },
  {
    capital: "West Island",
    code: "CC",
    continent: "AS",
    currency: "AUD",
    name: "Cocos [Keeling] Islands",
    phone: "61"
  },
  {
    capital: "Kinshasa",
    code: "CD",
    continent: "AF",
    currency: "CDF",
    name: "Democratic Republic of the Congo",
    phone: "243"
  },
  {
    capital: "Bangui",
    code: "CF",
    continent: "AF",
    currency: "XAF",
    name: "Central African Republic",
    phone: "236"
  },
  {
    capital: "Brazzaville",
    code: "CG",
    continent: "AF",
    currency: "XAF",
    name: "Republic of the Congo",
    phone: "242"
  },
  {
    capital: "Bern",
    code: "CH",
    continent: "EU",
    currency: "CHE,CHF,CHW",
    name: "Switzerland",
    phone: "41"
  },
  {
    capital: "Yamoussoukro",
    code: "CI",
    continent: "AF",
    currency: "XOF",
    name: "Ivory Coast",
    phone: "225"
  },
  {
    capital: "Avarua",
    code: "CK",
    continent: "OC",
    currency: "NZD",
    name: "Cook Islands",
    phone: "682"
  },
  {
    capital: "Santiago",
    code: "CL",
    continent: "SA",
    currency: "CLF,CLP",
    name: "Chile",
    phone: "56"
  },
  {
    capital: "Yaoundé",
    code: "CM",
    continent: "AF",
    currency: "XAF",
    name: "Cameroon",
    phone: "237"
  },
  {
    capital: "Beijing",
    code: "CN",
    continent: "AS",
    currency: "CNY",
    name: "China",
    phone: "86"
  },
  {
    capital: "Bogotá",
    code: "CO",
    continent: "SA",
    currency: "COP",
    name: "Colombia",
    phone: "57"
  },
  {
    capital: "San José",
    code: "CR",
    continent: "NA",
    currency: "CRC",
    name: "Costa Rica",
    phone: "506"
  },
  {
    capital: "Havana",
    code: "CU",
    continent: "NA",
    currency: "CUC,CUP",
    name: "Cuba",
    phone: "53"
  },
  {
    capital: "Praia",
    code: "CV",
    continent: "AF",
    currency: "CVE",
    name: "Cape Verde",
    phone: "238"
  },
  {
    capital: "Willemstad",
    code: "CW",
    continent: "NA",
    currency: "ANG",
    name: "Curacao",
    phone: "5999"
  },
  {
    capital: "Flying Fish Cove",
    code: "CX",
    continent: "AS",
    currency: "AUD",
    name: "Christmas Island",
    phone: "61"
  },
  {
    capital: "Nicosia",
    code: "CY",
    continent: "EU",
    currency: "EUR",
    name: "Cyprus",
    phone: "357"
  },
  {
    capital: "Prague",
    code: "CZ",
    continent: "EU",
    currency: "CZK",
    name: "Czech Republic",
    phone: "420"
  },
  {
    capital: "Berlin",
    code: "DE",
    continent: "EU",
    currency: "EUR",
    name: "Germany",
    phone: "49"
  },
  {
    capital: "Djibouti",
    code: "DJ",
    continent: "AF",
    currency: "DJF",
    name: "Djibouti",
    phone: "253"
  },
  {
    capital: "Copenhagen",
    code: "DK",
    continent: "EU",
    currency: "DKK",
    name: "Denmark",
    phone: "45"
  },
  {
    capital: "Roseau",
    code: "DM",
    continent: "NA",
    currency: "XCD",
    name: "Dominica",
    phone: "1767"
  },
  {
    capital: "Santo Domingo",
    code: "DO",
    continent: "NA",
    currency: "DOP",
    name: "Dominican Republic",
    phone: "1809,1829,1849"
  },
  {
    capital: "Algiers",
    code: "DZ",
    continent: "AF",
    currency: "DZD",
    name: "Algeria",
    phone: "213"
  },
  {
    capital: "Quito",
    code: "EC",
    continent: "SA",
    currency: "USD",
    name: "Ecuador",
    phone: "593"
  },
  {
    capital: "Tallinn",
    code: "EE",
    continent: "EU",
    currency: "EUR",
    name: "Estonia",
    phone: "372"
  },
  {
    capital: "Cairo",
    code: "EG",
    continent: "AF",
    currency: "EGP",
    name: "Egypt",
    phone: "20"
  },
  {
    capital: "El Aaiún",
    code: "EH",
    continent: "AF",
    currency: "MAD,DZD,MRO",
    name: "Western Sahara",
    phone: "212"
  },
  {
    capital: "Asmara",
    code: "ER",
    continent: "AF",
    currency: "ERN",
    name: "Eritrea",
    phone: "291"
  },
  {
    capital: "Madrid",
    code: "ES",
    continent: "EU",
    currency: "EUR",
    name: "Spain",
    phone: "34"
  },
  {
    capital: "Addis Ababa",
    code: "ET",
    continent: "AF",
    currency: "ETB",
    name: "Ethiopia",
    phone: "251"
  },
  {
    capital: "Helsinki",
    code: "FI",
    continent: "EU",
    currency: "EUR",
    name: "Finland",
    phone: "358"
  },
  {
    capital: "Suva",
    code: "FJ",
    continent: "OC",
    currency: "FJD",
    name: "Fiji",
    phone: "679"
  },
  {
    capital: "Stanley",
    code: "FK",
    continent: "SA",
    currency: "FKP",
    name: "Falkland Islands",
    phone: "500"
  },
  {
    capital: "Palikir",
    code: "FM",
    continent: "OC",
    currency: "USD",
    name: "Micronesia",
    phone: "691"
  },
  {
    capital: "Tórshavn",
    code: "FO",
    continent: "EU",
    currency: "DKK",
    name: "Faroe Islands",
    phone: "298"
  },
  {
    capital: "Paris",
    code: "FR",
    continent: "EU",
    currency: "EUR",
    name: "France",
    phone: "33"
  },
  {
    capital: "Libreville",
    code: "GA",
    continent: "AF",
    currency: "XAF",
    name: "Gabon",
    phone: "241"
  },
  {
    capital: "London",
    code: "GB",
    continent: "EU",
    currency: "GBP",
    name: "United Kingdom",
    phone: "44"
  },
  {
    capital: "St. George's",
    code: "GD",
    continent: "NA",
    currency: "XCD",
    name: "Grenada",
    phone: "1473"
  },
  {
    capital: "Tbilisi",
    code: "GE",
    continent: "AS",
    currency: "GEL",
    name: "Georgia",
    phone: "995"
  },
  {
    capital: "Cayenne",
    code: "GF",
    continent: "SA",
    currency: "EUR",
    name: "French Guiana",
    phone: "594"
  },
  {
    capital: "St. Peter Port",
    code: "GG",
    continent: "EU",
    currency: "GBP",
    name: "Guernsey",
    phone: "44"
  },
  {
    capital: "Accra",
    code: "GH",
    continent: "AF",
    currency: "GHS",
    name: "Ghana",
    phone: "233"
  },
  {
    capital: "Gibraltar",
    code: "GI",
    continent: "EU",
    currency: "GIP",
    name: "Gibraltar",
    phone: "350"
  },
  {
    capital: "Nuuk",
    code: "GL",
    continent: "NA",
    currency: "DKK",
    name: "Greenland",
    phone: "299"
  },
  {
    capital: "Banjul",
    code: "GM",
    continent: "AF",
    currency: "GMD",
    name: "Gambia",
    phone: "220"
  },
  {
    capital: "Conakry",
    code: "GN",
    continent: "AF",
    currency: "GNF",
    name: "Guinea",
    phone: "224"
  },
  {
    capital: "Basse-Terre",
    code: "GP",
    continent: "NA",
    currency: "EUR",
    name: "Guadeloupe",
    phone: "590"
  },
  {
    capital: "Malabo",
    code: "GQ",
    continent: "AF",
    currency: "XAF",
    name: "Equatorial Guinea",
    phone: "240"
  },
  {
    capital: "Athens",
    code: "GR",
    continent: "EU",
    currency: "EUR",
    name: "Greece",
    phone: "30"
  },
  {
    capital: "King Edward Point",
    code: "GS",
    continent: "AN",
    currency: "GBP",
    name: "South Georgia and the South Sandwich Islands",
    phone: "500"
  },
  {
    capital: "Guatemala City",
    code: "GT",
    continent: "NA",
    currency: "GTQ",
    name: "Guatemala",
    phone: "502"
  },
  {
    capital: "Hagåtña",
    code: "GU",
    continent: "OC",
    currency: "USD",
    name: "Guam",
    phone: "1671"
  },
  {
    capital: "Bissau",
    code: "GW",
    continent: "AF",
    currency: "XOF",
    name: "Guinea-Bissau",
    phone: "245"
  },
  {
    capital: "Georgetown",
    code: "GY",
    continent: "SA",
    currency: "GYD",
    name: "Guyana",
    phone: "592"
  },
  {
    capital: "City of Victoria",
    code: "HK",
    continent: "AS",
    currency: "HKD",
    name: "Hong Kong",
    phone: "852"
  },
  {
    capital: "",
    code: "HM",
    continent: "AN",
    currency: "AUD",
    name: "Heard Island and McDonald Islands",
    phone: "61"
  },
  {
    capital: "Tegucigalpa",
    code: "HN",
    continent: "NA",
    currency: "HNL",
    name: "Honduras",
    phone: "504"
  },
  {
    capital: "Zagreb",
    code: "HR",
    continent: "EU",
    currency: "HRK",
    name: "Croatia",
    phone: "385"
  },
  {
    capital: "Port-au-Prince",
    code: "HT",
    continent: "NA",
    currency: "HTG,USD",
    name: "Haiti",
    phone: "509"
  },
  {
    capital: "Budapest",
    code: "HU",
    continent: "EU",
    currency: "HUF",
    name: "Hungary",
    phone: "36"
  },
  {
    capital: "Jakarta",
    code: "ID",
    continent: "AS",
    currency: "IDR",
    name: "Indonesia",
    phone: "62"
  },
  {
    capital: "Dublin",
    code: "IE",
    continent: "EU",
    currency: "EUR",
    name: "Ireland",
    phone: "353"
  },
  {
    capital: "Jerusalem",
    code: "IL",
    continent: "AS",
    currency: "ILS",
    name: "Israel",
    phone: "972"
  },
  {
    capital: "Douglas",
    code: "IM",
    continent: "EU",
    currency: "GBP",
    name: "Isle of Man",
    phone: "44"
  },
  {
    capital: "New Delhi",
    code: "IN",
    continent: "AS",
    currency: "INR",
    name: "India",
    phone: "91"
  },
  {
    capital: "Diego Garcia",
    code: "IO",
    continent: "AS",
    currency: "USD",
    name: "British Indian Ocean Territory",
    phone: "246"
  },
  {
    capital: "Baghdad",
    code: "IQ",
    continent: "AS",
    currency: "IQD",
    name: "Iraq",
    phone: "964"
  },
  {
    capital: "Tehran",
    code: "IR",
    continent: "AS",
    currency: "IRR",
    name: "Iran",
    phone: "98"
  },
  {
    capital: "Reykjavik",
    code: "IS",
    continent: "EU",
    currency: "ISK",
    name: "Iceland",
    phone: "354"
  },
  {
    capital: "Rome",
    code: "IT",
    continent: "EU",
    currency: "EUR",
    name: "Italy",
    phone: "39"
  },
  {
    capital: "Saint Helier",
    code: "JE",
    continent: "EU",
    currency: "GBP",
    name: "Jersey",
    phone: "44"
  },
  {
    capital: "Kingston",
    code: "JM",
    continent: "NA",
    currency: "JMD",
    name: "Jamaica",
    phone: "1876"
  },
  {
    capital: "Amman",
    code: "JO",
    continent: "AS",
    currency: "JOD",
    name: "Jordan",
    phone: "962"
  },
  {
    capital: "Tokyo",
    code: "JP",
    continent: "AS",
    currency: "JPY",
    name: "Japan",
    phone: "81"
  },
  {
    capital: "Nairobi",
    code: "KE",
    continent: "AF",
    currency: "KES",
    name: "Kenya",
    phone: "254"
  },
  {
    capital: "Bishkek",
    code: "KG",
    continent: "AS",
    currency: "KGS",
    name: "Kyrgyzstan",
    phone: "996"
  },
  {
    capital: "Phnom Penh",
    code: "KH",
    continent: "AS",
    currency: "KHR",
    name: "Cambodia",
    phone: "855"
  },
  {
    capital: "South Tarawa",
    code: "KI",
    continent: "OC",
    currency: "AUD",
    name: "Kiribati",
    phone: "686"
  },
  {
    capital: "Moroni",
    code: "KM",
    continent: "AF",
    currency: "KMF",
    name: "Comoros",
    phone: "269"
  },
  {
    capital: "Basseterre",
    code: "KN",
    continent: "NA",
    currency: "XCD",
    name: "Saint Kitts and Nevis",
    phone: "1869"
  },
  {
    capital: "Pyongyang",
    code: "KP",
    continent: "AS",
    currency: "KPW",
    name: "North Korea",
    phone: "850"
  },
  {
    capital: "Seoul",
    code: "KR",
    continent: "AS",
    currency: "KRW",
    name: "South Korea",
    phone: "82"
  },
  {
    capital: "Kuwait City",
    code: "KW",
    continent: "AS",
    currency: "KWD",
    name: "Kuwait",
    phone: "965"
  },
  {
    capital: "George Town",
    code: "KY",
    continent: "NA",
    currency: "KYD",
    name: "Cayman Islands",
    phone: "1345"
  },
  {
    capital: "Astana",
    code: "KZ",
    continent: "AS",
    currency: "KZT",
    name: "Kazakhstan",
    phone: "76,77"
  },
  {
    capital: "Vientiane",
    code: "LA",
    continent: "AS",
    currency: "LAK",
    name: "Laos",
    phone: "856"
  },
  {
    capital: "Beirut",
    code: "LB",
    continent: "AS",
    currency: "LBP",
    name: "Lebanon",
    phone: "961"
  },
  {
    capital: "Castries",
    code: "LC",
    continent: "NA",
    currency: "XCD",
    name: "Saint Lucia",
    phone: "1758"
  },
  {
    capital: "Vaduz",
    code: "LI",
    continent: "EU",
    currency: "CHF",
    name: "Liechtenstein",
    phone: "423"
  },
  {
    capital: "Colombo",
    code: "LK",
    continent: "AS",
    currency: "LKR",
    name: "Sri Lanka",
    phone: "94"
  },
  {
    capital: "Monrovia",
    code: "LR",
    continent: "AF",
    currency: "LRD",
    name: "Liberia",
    phone: "231"
  },
  {
    capital: "Maseru",
    code: "LS",
    continent: "AF",
    currency: "LSL,ZAR",
    name: "Lesotho",
    phone: "266"
  },
  {
    capital: "Vilnius",
    code: "LT",
    continent: "EU",
    currency: "LTL",
    name: "Lithuania",
    phone: "370"
  },
  {
    capital: "Luxembourg",
    code: "LU",
    continent: "EU",
    currency: "EUR",
    name: "Luxembourg",
    phone: "352"
  },
  {
    capital: "Riga",
    code: "LV",
    continent: "EU",
    currency: "EUR",
    name: "Latvia",
    phone: "371"
  },
  {
    capital: "Tripoli",
    code: "LY",
    continent: "AF",
    currency: "LYD",
    name: "Libya",
    phone: "218"
  },
  {
    capital: "Rabat",
    code: "MA",
    continent: "AF",
    currency: "MAD",
    name: "Morocco",
    phone: "212"
  },
  {
    capital: "Monaco",
    code: "MC",
    continent: "EU",
    currency: "EUR",
    name: "Monaco",
    phone: "377"
  },
  {
    capital: "Chișinău",
    code: "MD",
    continent: "EU",
    currency: "MDL",
    name: "Moldova",
    phone: "373"
  },
  {
    capital: "Podgorica",
    code: "ME",
    continent: "EU",
    currency: "EUR",
    name: "Montenegro",
    phone: "382"
  },
  {
    capital: "Marigot",
    code: "MF",
    continent: "NA",
    currency: "EUR",
    name: "Saint Martin",
    phone: "590"
  },
  {
    capital: "Antananarivo",
    code: "MG",
    continent: "AF",
    currency: "MGA",
    name: "Madagascar",
    phone: "261"
  },
  {
    capital: "Majuro",
    code: "MH",
    continent: "OC",
    currency: "USD",
    name: "Marshall Islands",
    phone: "692"
  },
  {
    capital: "Skopje",
    code: "MK",
    continent: "EU",
    currency: "MKD",
    name: "Macedonia",
    phone: "389"
  },
  {
    capital: "Bamako",
    code: "ML",
    continent: "AF",
    currency: "XOF",
    name: "Mali",
    phone: "223"
  },
  {
    capital: "Naypyidaw",
    code: "MM",
    continent: "AS",
    currency: "MMK",
    name: "Myanmar [Burma]",
    phone: "95"
  },
  {
    capital: "Ulan Bator",
    code: "MN",
    continent: "AS",
    currency: "MNT",
    name: "Mongolia",
    phone: "976"
  },
  {
    capital: "",
    code: "MO",
    continent: "AS",
    currency: "MOP",
    name: "Macao",
    phone: "853"
  },
  {
    capital: "Saipan",
    code: "MP",
    continent: "OC",
    currency: "USD",
    name: "Northern Mariana Islands",
    phone: "1670"
  },
  {
    capital: "Fort-de-France",
    code: "MQ",
    continent: "NA",
    currency: "EUR",
    name: "Martinique",
    phone: "596"
  },
  {
    capital: "Nouakchott",
    code: "MR",
    continent: "AF",
    currency: "MRO",
    name: "Mauritania",
    phone: "222"
  },
  {
    capital: "Plymouth",
    code: "MS",
    continent: "NA",
    currency: "XCD",
    name: "Montserrat",
    phone: "1664"
  },
  {
    capital: "Valletta",
    code: "MT",
    continent: "EU",
    currency: "EUR",
    name: "Malta",
    phone: "356"
  },
  {
    capital: "Port Louis",
    code: "MU",
    continent: "AF",
    currency: "MUR",
    name: "Mauritius",
    phone: "230"
  },
  {
    capital: "Malé",
    code: "MV",
    continent: "AS",
    currency: "MVR",
    name: "Maldives",
    phone: "960"
  },
  {
    capital: "Lilongwe",
    code: "MW",
    continent: "AF",
    currency: "MWK",
    name: "Malawi",
    phone: "265"
  },
  {
    capital: "Mexico City",
    code: "MX",
    continent: "NA",
    currency: "MXN",
    name: "Mexico",
    phone: "52"
  },
  {
    capital: "Kuala Lumpur",
    code: "MY",
    continent: "AS",
    currency: "MYR",
    name: "Malaysia",
    phone: "60"
  },
  {
    capital: "Maputo",
    code: "MZ",
    continent: "AF",
    currency: "MZN",
    name: "Mozambique",
    phone: "258"
  },
  {
    capital: "Windhoek",
    code: "NA",
    continent: "AF",
    currency: "NAD,ZAR",
    name: "Namibia",
    phone: "264"
  },
  {
    capital: "Nouméa",
    code: "NC",
    continent: "OC",
    currency: "XPF",
    name: "New Caledonia",
    phone: "687"
  },
  {
    capital: "Niamey",
    code: "NE",
    continent: "AF",
    currency: "XOF",
    name: "Niger",
    phone: "227"
  },
  {
    capital: "Kingston",
    code: "NF",
    continent: "OC",
    currency: "AUD",
    name: "Norfolk Island",
    phone: "672"
  },
  {
    capital: "Abuja",
    code: "NG",
    continent: "AF",
    currency: "NGN",
    name: "Nigeria",
    phone: "234"
  },
  {
    capital: "Managua",
    code: "NI",
    continent: "NA",
    currency: "NIO",
    name: "Nicaragua",
    phone: "505"
  },
  {
    capital: "Amsterdam",
    code: "NL",
    continent: "EU",
    currency: "EUR",
    name: "Netherlands",
    phone: "31"
  },
  {
    capital: "Oslo",
    code: "NO",
    continent: "EU",
    currency: "NOK",
    name: "Norway",
    phone: "47"
  },
  {
    capital: "Kathmandu",
    code: "NP",
    continent: "AS",
    currency: "NPR",
    name: "Nepal",
    phone: "977"
  },
  {
    capital: "Yaren",
    code: "NR",
    continent: "OC",
    currency: "AUD",
    name: "Nauru",
    phone: "674"
  },
  {
    capital: "Alofi",
    code: "NU",
    continent: "OC",
    currency: "NZD",
    name: "Niue",
    phone: "683"
  },
  {
    capital: "Wellington",
    code: "NZ",
    continent: "OC",
    currency: "NZD",
    name: "New Zealand",
    phone: "64"
  },
  {
    capital: "Muscat",
    code: "OM",
    continent: "AS",
    currency: "OMR",
    name: "Oman",
    phone: "968"
  },
  {
    capital: "Panama City",
    code: "PA",
    continent: "NA",
    currency: "PAB,USD",
    name: "Panama",
    phone: "507"
  },
  {
    capital: "Lima",
    code: "PE",
    continent: "SA",
    currency: "PEN",
    name: "Peru",
    phone: "51"
  },
  {
    capital: "Papeetē",
    code: "PF",
    continent: "OC",
    currency: "XPF",
    name: "French Polynesia",
    phone: "689"
  },
  {
    capital: "Port Moresby",
    code: "PG",
    continent: "OC",
    currency: "PGK",
    name: "Papua New Guinea",
    phone: "675"
  },
  {
    capital: "Manila",
    code: "PH",
    continent: "AS",
    currency: "PHP",
    name: "Philippines",
    phone: "63"
  },
  {
    capital: "Islamabad",
    code: "PK",
    continent: "AS",
    currency: "PKR",
    name: "Pakistan",
    phone: "92"
  },
  {
    capital: "Warsaw",
    code: "PL",
    continent: "EU",
    currency: "PLN",
    name: "Poland",
    phone: "48"
  },
  {
    capital: "Saint-Pierre",
    code: "PM",
    continent: "NA",
    currency: "EUR",
    name: "Saint Pierre and Miquelon",
    phone: "508"
  },
  {
    capital: "Adamstown",
    code: "PN",
    continent: "OC",
    currency: "NZD",
    name: "Pitcairn Islands",
    phone: "64"
  },
  {
    capital: "San Juan",
    code: "PR",
    continent: "NA",
    currency: "USD",
    name: "Puerto Rico",
    phone: "1787,1939"
  },
  {
    capital: "Ramallah",
    code: "PS",
    continent: "AS",
    currency: "ILS",
    name: "Palestine",
    phone: "970"
  },
  {
    capital: "Lisbon",
    code: "PT",
    continent: "EU",
    currency: "EUR",
    name: "Portugal",
    phone: "351"
  },
  {
    capital: "Ngerulmud",
    code: "PW",
    continent: "OC",
    currency: "USD",
    name: "Palau",
    phone: "680"
  },
  {
    capital: "Asunción",
    code: "PY",
    continent: "SA",
    currency: "PYG",
    name: "Paraguay",
    phone: "595"
  },
  {
    capital: "Doha",
    code: "QA",
    continent: "AS",
    currency: "QAR",
    name: "Qatar",
    phone: "974"
  },
  {
    capital: "Saint-Denis",
    code: "RE",
    continent: "AF",
    currency: "EUR",
    name: "Réunion",
    phone: "262"
  },
  {
    capital: "Bucharest",
    code: "RO",
    continent: "EU",
    currency: "RON",
    name: "Romania",
    phone: "40"
  },
  {
    capital: "Belgrade",
    code: "RS",
    continent: "EU",
    currency: "RSD",
    name: "Serbia",
    phone: "381"
  },
  {
    capital: "Moscow",
    code: "RU",
    continent: "EU",
    currency: "RUB",
    name: "Russia",
    phone: "7"
  },
  {
    capital: "Kigali",
    code: "RW",
    continent: "AF",
    currency: "RWF",
    name: "Rwanda",
    phone: "250"
  },
  {
    capital: "Riyadh",
    code: "SA",
    continent: "AS",
    currency: "SAR",
    name: "Saudi Arabia",
    phone: "966"
  },
  {
    capital: "Honiara",
    code: "SB",
    continent: "OC",
    currency: "SBD",
    name: "Solomon Islands",
    phone: "677"
  },
  {
    capital: "Victoria",
    code: "SC",
    continent: "AF",
    currency: "SCR",
    name: "Seychelles",
    phone: "248"
  },
  {
    capital: "Khartoum",
    code: "SD",
    continent: "AF",
    currency: "SDG",
    name: "Sudan",
    phone: "249"
  },
  {
    capital: "Stockholm",
    code: "SE",
    continent: "EU",
    currency: "SEK",
    name: "Sweden",
    phone: "46"
  },
  {
    capital: "Singapore",
    code: "SG",
    continent: "AS",
    currency: "SGD",
    name: "Singapore",
    phone: "65"
  },
  {
    capital: "Jamestown",
    code: "SH",
    continent: "AF",
    currency: "SHP",
    name: "Saint Helena",
    phone: "290"
  },
  {
    capital: "Ljubljana",
    code: "SI",
    continent: "EU",
    currency: "EUR",
    name: "Slovenia",
    phone: "386"
  },
  {
    capital: "Longyearbyen",
    code: "SJ",
    continent: "EU",
    currency: "NOK",
    name: "Svalbard and Jan Mayen",
    phone: "4779"
  },
  {
    capital: "Bratislava",
    code: "SK",
    continent: "EU",
    currency: "EUR",
    name: "Slovakia",
    phone: "421"
  },
  {
    capital: "Freetown",
    code: "SL",
    continent: "AF",
    currency: "SLL",
    name: "Sierra Leone",
    phone: "232"
  },
  {
    capital: "City of San Marino",
    code: "SM",
    continent: "EU",
    currency: "EUR",
    name: "San Marino",
    phone: "378"
  },
  {
    capital: "Dakar",
    code: "SN",
    continent: "AF",
    currency: "XOF",
    name: "Senegal",
    phone: "221"
  },
  {
    capital: "Mogadishu",
    code: "SO",
    continent: "AF",
    currency: "SOS",
    name: "Somalia",
    phone: "252"
  },
  {
    capital: "Paramaribo",
    code: "SR",
    continent: "SA",
    currency: "SRD",
    name: "Suriname",
    phone: "597"
  },
  {
    capital: "Juba",
    code: "SS",
    continent: "AF",
    currency: "SSP",
    name: "South Sudan",
    phone: "211"
  },
  {
    capital: "São Tomé",
    code: "ST",
    continent: "AF",
    currency: "STD",
    name: "São Tomé and Príncipe",
    phone: "239"
  },
  {
    capital: "San Salvador",
    code: "SV",
    continent: "NA",
    currency: "SVC,USD",
    name: "El Salvador",
    phone: "503"
  },
  {
    capital: "Philipsburg",
    code: "SX",
    continent: "NA",
    currency: "ANG",
    name: "Sint Maarten",
    phone: "1721"
  },
  {
    capital: "Damascus",
    code: "SY",
    continent: "AS",
    currency: "SYP",
    name: "Syria",
    phone: "963"
  },
  {
    capital: "Lobamba",
    code: "SZ",
    continent: "AF",
    currency: "SZL",
    name: "Swaziland",
    phone: "268"
  },
  {
    capital: "Cockburn Town",
    code: "TC",
    continent: "NA",
    currency: "USD",
    name: "Turks and Caicos Islands",
    phone: "1649"
  },
  {
    capital: "N'Djamena",
    code: "TD",
    continent: "AF",
    currency: "XAF",
    name: "Chad",
    phone: "235"
  },
  {
    capital: "Port-aux-Français",
    code: "TF",
    continent: "AN",
    currency: "EUR",
    name: "French Southern Territories",
    phone: "262"
  },
  {
    capital: "Lomé",
    code: "TG",
    continent: "AF",
    currency: "XOF",
    name: "Togo",
    phone: "228"
  },
  {
    capital: "Bangkok",
    code: "TH",
    continent: "AS",
    currency: "THB",
    name: "Thailand",
    phone: "66"
  },
  {
    capital: "Dushanbe",
    code: "TJ",
    continent: "AS",
    currency: "TJS",
    name: "Tajikistan",
    phone: "992"
  },
  {
    capital: "Fakaofo",
    code: "TK",
    continent: "OC",
    currency: "NZD",
    name: "Tokelau",
    phone: "690"
  },
  {
    capital: "Dili",
    code: "TL",
    continent: "OC",
    currency: "USD",
    name: "East Timor",
    phone: "670"
  },
  {
    capital: "Ashgabat",
    code: "TM",
    continent: "AS",
    currency: "TMT",
    name: "Turkmenistan",
    phone: "993"
  },
  {
    capital: "Tunis",
    code: "TN",
    continent: "AF",
    currency: "TND",
    name: "Tunisia",
    phone: "216"
  },
  {
    capital: "Nuku'alofa",
    code: "TO",
    continent: "OC",
    currency: "TOP",
    name: "Tonga",
    phone: "676"
  },
  {
    capital: "Ankara",
    code: "TR",
    continent: "AS",
    currency: "TRY",
    name: "Turkey",
    phone: "90"
  },
  {
    capital: "Port of Spain",
    code: "TT",
    continent: "NA",
    currency: "TTD",
    name: "Trinidad and Tobago",
    phone: "1868"
  },
  {
    capital: "Funafuti",
    code: "TV",
    continent: "OC",
    currency: "AUD",
    name: "Tuvalu",
    phone: "688"
  },
  {
    capital: "Taipei",
    code: "TW",
    continent: "AS",
    currency: "TWD",
    name: "Taiwan",
    phone: "886"
  },
  {
    capital: "Dodoma",
    code: "TZ",
    continent: "AF",
    currency: "TZS",
    name: "Tanzania",
    phone: "255"
  },
  {
    capital: "Kyiv",
    code: "UA",
    continent: "EU",
    currency: "UAH",
    name: "Ukraine",
    phone: "380"
  },
  {
    capital: "Kampala",
    code: "UG",
    continent: "AF",
    currency: "UGX",
    name: "Uganda",
    phone: "256"
  },
  {
    capital: "",
    code: "UM",
    continent: "OC",
    currency: "USD",
    name: "U.S. Minor Outlying Islands",
    phone: "1"
  },
  {
    capital: "Washington D.C.",
    code: "US",
    continent: "NA",
    currency: "USD,USN,USS",
    name: "United States",
    phone: "1"
  },
  {
    capital: "Montevideo",
    code: "UY",
    continent: "SA",
    currency: "UYI,UYU",
    name: "Uruguay",
    phone: "598"
  },
  {
    capital: "Tashkent",
    code: "UZ",
    continent: "AS",
    currency: "UZS",
    name: "Uzbekistan",
    phone: "998"
  },
  {
    capital: "Vatican City",
    code: "VA",
    continent: "EU",
    currency: "EUR",
    name: "Vatican City",
    phone: "39066,379"
  },
  {
    capital: "Kingstown",
    code: "VC",
    continent: "NA",
    currency: "XCD",
    name: "Saint Vincent and the Grenadines",
    phone: "1784"
  },
  {
    capital: "Caracas",
    code: "VE",
    continent: "SA",
    currency: "VEF",
    name: "Venezuela",
    phone: "58"
  },
  {
    capital: "Road Town",
    code: "VG",
    continent: "NA",
    currency: "USD",
    name: "British Virgin Islands",
    phone: "1284"
  },
  {
    capital: "Charlotte Amalie",
    code: "VI",
    continent: "NA",
    currency: "USD",
    name: "U.S. Virgin Islands",
    phone: "1340"
  },
  {
    capital: "Hanoi",
    code: "VN",
    continent: "AS",
    currency: "VND",
    name: "Vietnam",
    phone: "84"
  },
  {
    capital: "Port Vila",
    code: "VU",
    continent: "OC",
    currency: "VUV",
    name: "Vanuatu",
    phone: "678"
  },
  {
    capital: "Mata-Utu",
    code: "WF",
    continent: "OC",
    currency: "XPF",
    name: "Wallis and Futuna",
    phone: "681"
  },
  {
    capital: "Apia",
    code: "WS",
    continent: "OC",
    currency: "WST",
    name: "Samoa",
    phone: "685"
  },
  {
    capital: "Pristina",
    code: "XK",
    continent: "EU",
    currency: "EUR",
    name: "Kosovo",
    phone: "377,381,383,386"
  },
  {
    capital: "Sana'a",
    code: "YE",
    continent: "AS",
    currency: "YER",
    name: "Yemen",
    phone: "967"
  },
  {
    capital: "Mamoudzou",
    code: "YT",
    continent: "AF",
    currency: "EUR",
    name: "Mayotte",
    phone: "262"
  },
  {
    capital: "Pretoria",
    code: "ZA",
    continent: "AF",
    currency: "ZAR",
    name: "South Africa",
    phone: "27"
  },
  {
    capital: "Lusaka",
    code: "ZM",
    continent: "AF",
    currency: "ZMK",
    name: "Zambia",
    phone: "260"
  },
  {
    capital: "Harare",
    code: "ZW",
    continent: "AF",
    currency: "ZWL",
    name: "Zimbabwe",
    phone: "263"
  }
];
