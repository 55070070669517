import React from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';

import { isSectionVisible, withEuroOrDash, withPercentOrDash } from '../../../utils/utils';
import ReturnValue from './components/ReturnValue';

import useAggregatedReturnSectionStyles from './styles';


const AggregatedPortfolioReturnSection = ({data, dataKey, customSettings, reportType}) => {

  const classes = useAggregatedReturnSectionStyles();

  if (!data) return null;

  const values = _.get(data, `data.${dataKey}`) || {};

  return (
    <Grid container spacing={4}>
      {isSectionVisible(customSettings, 'profit_perc', reportType) && (
        <Grid item className={classes.wrapper}>
          <span className={classes.title}>GuV (%)</span>
          <span className={classes.value}>
            <ReturnValue value={values.percentage} valueHandler={withPercentOrDash} />
          </span>
        </Grid>
      )}
      {isSectionVisible(customSettings, 'profit_amount', reportType) && (
        <Grid item className={classes.wrapper}>
          <span className={classes.title}>GuV (€)</span>
          <span className={classes.value}>
            <ReturnValue value={values.value} valueHandler={withEuroOrDash}/>
          </span>
        </Grid>
      )}
      {isSectionVisible(customSettings, 'profit_pa', reportType) && (
        <Grid item className={classes.wrapper}>
          <span className={classes.title}>GuV p.a. (%)</span>
          <span className={classes.value}>
            <ReturnValue value={values.percentage_pa} valueHandler={withPercentOrDash}/>
          </span>
        </Grid>
      )}
    </Grid>
  )
};

export default AggregatedPortfolioReturnSection;